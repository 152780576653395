import React from "react";
import "../../assets/scss/blogs/sprintReview.scss";
import PageBanner from "../PageBanner";
import Comments from "./Comments";

const SprintReview = () => {
  return (
    <div className="sprintReview-main">
      <PageBanner section="sprintReview" />
      <div className="train-your-main-sprint">
        <div className="tyt-text-sprint">
          <div className="head-para-sprint">
            The Sprint Retrospective is a Scrum event held at the end of each
            Sprint which evaluates what went well during the Sprint and
            identifies areas for improvement. It's inevitable that issues will
            arise during any Sprint, but how the team learns from these setbacks
            can make or break their progress.
          </div>
        </div>
      </div>
      <div className="train-your-main-sprint">
        <div className="tyt-text-sprint">
          <div className="each-para-div-sprint">
            The goal of a retrospective is for a team to gain insight into their
            processes and to adapt their methods accordingly. It's important to
            note that retrospectives are not only for Agile teams - in fact,
            they can be used by any team that wants to improve their efficiency
            and performance. That being said, Agile teams tend to benefit the
            most from retrospectives since they're constantly changing and
            evolving. After all, one of the core tenets of Agile is to "inspect
            and adapt".
          </div>
        </div>
      </div>
      <div className="train-your-main-sprint">
        <div className="tyt-text-sprint">
          <div className="each-para-div-sprint">
            Remember to be creative with what you discuss! This process isn't
            just about looking back at how things were done but also dreaming up
            ways you can improve going forward. It's very much an opportunity
            that encourages creativity within every individual during these
            meetings. No matter how effective your team's Sprint Retrospectives
            are, there’s always room for improvement. Here are five steps to
            make them even better.
          </div>
        </div>
      </div>
      <div className="train-your-main-sprint">
        <div className="tyt-paras-sprint flex-dir-sprint">
          <div className="secs-tyt-cols-sprint">
            <div className="tyt-text-sprint">
              <div className="tyt-sub-heading-sprint">
                Step 1. Engaging Icebreakers
              </div>
              <div>
                Icebreakers are a great way to start a meeting. They can be as
                simple as a question to jog your memory, or a game to get team
                members excited. No matter what you choose, it plays the role of
                getting people relaxed, engaged, and facilitates team bonding.
                It becomes a pathway to a more transparent and effective retro
                where the team can feel comfortable when sharing their thoughts
                on
                <span style={{ fontFamily: "Lokem-Medium" }}>
                  what went well, what can be improved
                </span>
                and
                <span style={{ fontFamily: "Lokem-Medium" }}>
                  what to action.
                </span>
                Next time, to get the meeting started, why not try playing a
                game of charades or asking each team member to think of the
                oldest object they have in their house and to tell a short story
                about it?
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="train-your-main-sprint">
        <div className="tyt-paras-sprint">
          <div className="secs-tyt-cols-sprint">
            <div className="tyt-text-sprint">
              <div className="tyt-sub-heading-sprint">
                Step 2. Rewind, Recall, Roundup
              </div>
              <div className="each-para-div-sprint">
                <div>
                  Begin by asking your team what their goals are for the session
                  in order to establish context. Once the stage has been set,
                  encourage the team to offer opinions on what they did well,
                  rather than focusing on specific areas for improvement. You
                  can do this by going around the room and asking each person to
                  share one thing they're proud of from the last Sprint, or even
                  something they struggled with. This will help remove tension
                  from the room and reinforce camaraderie.
                </div>
              </div>

              <div className="each-para-div-sprint">
                <div>
                  Don’t be afraid of laughter! It's crucial that during these
                  meetings people can have fun working alongside one another
                  even when it seems like the tasks are simple. It's important
                  to let people express themselves openly and honestly. After
                  all, the whole point of the retrospective is to identify
                  issues so that they can be addressed. Some great techniques
                  are as follows:
                </div>
              </div>

              <div className="each-para-div-sprint">
                <div>
                  The Mad, Sad, Glad Retrospective - this uses emotional
                  responses as a prompt to help the team communicate any
                  thoughts or feelings that came up in the past Sprint. It also
                  boosts morale, improves emotional well-being and creates an
                  environment where everyone can work together towards common
                  goals.
                </div>
              </div>

              <div className="each-para-div-sprint">
                <div>
                  The Sailboat Retrospective - this is a fun way to get everyone
                  thinking about their ideas for improvement. It's a way of
                  visually identifying and representing what pushed the project
                  forward, as well as what held it back.
                </div>
              </div>

              <div className="each-para-div-sprint">
                <div>
                  The KALM (Keep, Add, Less, and More) Retrospective - this
                  format helps structure the meeting by focusing on how the team
                  felt about specific ideas and processes in the Sprint, and
                  adjusting how much they use them to help better the team in
                  future increments.
                </div>
              </div>
              <div className="each-para-div-sprint">
                <div>
                  Once the stage has been set and the responses have been
                  gathered, it's time to begin brainstorming to discover
                  insights.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-sprint">
        <div className="tyt-paras-sprint">
          <div className="tyt-text-sprint">
            <div className="tyt-sub-heading-sprint">
              Step 3. Generate Insights
            </div>
            <div className="each-para-div-sprint">
              Here is where the true benefits of retrospectives become apparent.
              The first step is to use the data from your chosen metric to help
              unearth insights that will enhance your team’s collaboration. For
              example, the happiness metric could be used to detect feelings of
              burnout within the team, whereas noticing a lack of
              congratulations in the Mad, Sad, Glad Retrospective could suggest
              team members are unaware of each others’ achievements.
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-sprint">
        <div className="tyt-paras-sprint">
          <div className="tyt-text-sprint">
            <div className="tyt-sub-heading-sprint">
              Step 4. Ready, Solution, Action!
            </div>
            <div className="each-para-div-sprint">
              This is where the team comes up with potential solutions to the
              problems identified in the previous step. It's important to
              encourage everyone to come up with as many ideas as possible. The
              more options you have on the table, the better! After all, you
              want to find the best possible solution.
            </div>

            <div className="each-para-div-sprint">
              You can use the ‘Start, Stop, and Continue’ technique to
              brainstorm actions and come up with a list of possible solutions.
              The team then votes on which ones they want to implement using the
              dot voting system. Keep things simple and focus on what will have
              the biggest impact.
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-sprint">
        <div className="tyt-paras-sprint">
          <div className="tyt-text-sprint">
            <div className="tyt-sub-heading-sprint">
              Step 5. Reflect, Close, Celebrate!
            </div>
            <div className="each-para-div-sprint">
              The final step is to reflect on what was discussed and agreed upon
              during the retrospective. This is a good time to set goals for the
              next Sprint and to make sure that everyone is on the same page.
              It's also important to thank everyone for their participation and
              contributions. Remember, it’s a team effort!
            </div>

            <div className="each-para-div-sprint">
              <div className="each-para-div-sprint">
                Retrospectives are a valuable tool for any Agile team and are a
                great way to make sure your team is on the same page and focused
                for the next Sprint. By using the techniques outlined in this
                article, you can get the most out of your retrospectives and
                help your team to continuously improve.
              </div>

              <div className="each-para-div-sprint">
                Which technique will you try first?
              </div>
            </div>
          </div>
        </div>
      </div>
      <Comments />
    </div>
  );
};

export default SprintReview;
