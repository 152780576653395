import React from "react";
import { Link } from "react-router-dom";
import "../assets/scss/scrumMaster.scss";

//Section 2
import img1 from "../assets/images/training1.jpeg";
import img2 from "../assets/images/training2.jpeg";
//Section 3
import img3 from "../assets/images/sm-experts.png";
import img4 from "../assets/images/sm-mindset.png";
import img5 from "../assets/images/sm-agility.png";
import img6 from "../assets/images/sm-delivery.png";
//Section 5
import img8 from "../assets/images/sm-jouney-map.png";
import { useNavigate } from "react-router-dom";
import AdvCoupon from "../pages/AdvCoupon";

const ScrumMaster = () => {
  const navigate = useNavigate();
  return (
  <>
  <AdvCoupon />
    <div className="scrumMaster-main">
      
        
      {/* Banner */}
      <div className="top-page-sm">
      <div className="first-page-container-sm">
        <div className="first-page-heading-sm banner-heading lokem-headers-sm">
          Scrum Master Training
        </div>
      </div>
      <div className="training-dates-button-section-sm book-btn-position-sm">
        <button
          className="book-place-button-sm lokem-button-sm"
         data-course="1" onClick={() => navigate("/scrum-master")}
        >
          BOOK NOW
        </button>
      </div>
    </div>


      {/* Section 2 */}
      <div className="train-your-main-sm training-overview-main-sm">
      <div className="max-width-div-sm">
        <div className="overview-cols-main-sm">
          {/* Column 1 */}
          <div className="overview-col1-sm">
            <div className="tyt-heading-main-sm">
              <div className="tyt-heading-sm tyt-heading-text-sm">Training Overview</div>
            </div>
            <div className="tyt-text-sm">
              <div className="each-para-div-sm">
                <div>
                  With our Scrum Master Course, you will learn to apply Scrum principles and values to various types of teamwork and you will implement the practical aspects of Scrum by working on live projects. We don't want you just to learn Scrum, we want you to succeed in it.
                </div>
              </div>
              <div className="each-para-div-sm">
                <div>
                  At Lokem, our training helps everyone understand the Scrum framework and provides a level of knowledge that allows people to be more efficient and effective in its practise.
                </div>
              </div>
              <div className="each-para-div-sm">
                <div>
                  This training is for leaders, managers, and other professionals seeking to gain in-depth knowledge on Scrum and learn how to revolutionise their way of delivering projects.
                </div>
              </div>
              <div className="each-para-div-sm">
                <div>
                  The training is also suitable for anyone who is new to implementing Scrum.
                </div>
              </div>
              <div className="each-para-div-sm">
                <div>
                  Looking for a change in career? New to Project Management? No prior IT knowledge or background? No problem. We give you all the skills and knowledge to succeed.
                </div>
              </div>
              <div className="each-para-div-sm increase-font-sm">
                <div>
                  We coach, mentor and guide you in becoming a Professional Scrum Master.
                </div>
              </div>
            </div>
          </div>

          {/* Column 2 */}
          <div className="overview-col2-sm">
            <div>
              <img
                className="course-overview-image1-sm"
                src={img1}
                alt="Scrum Training Overview 1"
              />
            </div>
            <div>
              <img
                className="course-overview-image2-sm"
                src={img2}
                alt="Scrum Training Overview 2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

      {/* Section 3*/}
      <div className="tobj-main-sm">
      <div className="tyt-heading-sm tyt-heading-text-sm tobj-heading-sm">Training Objectives</div>
      <div className="tobj-sub-sm">Students learn to:</div>
      <div className="tobj-col-main-sm tyt-text-sm">
        <div className="tobj-row-sm">
          <div className="tobj-col1-sm">
            <div className="tyt-sub-heading-sm tobj-color-sm">
              <img src={img3} className="tobj-img-sm" alt="Expertise" />
            </div>
            <div className="each-para-div-sm">
              <div>
                Be experts in the popular Agile framework.
              </div>
            </div>
          </div>
          <div className="tobj-col1-sm">
            <div className="tyt-sub-heading-sm tobj-color-sm">
              <img src={img4} className="tobj-img-sm" alt="Mindset" />
            </div>
            <div className="each-para-div-sm">
              <div>
                Adopt an Agile mindset and achieve success in helping organisations evolve.
              </div>
            </div>
          </div>
        </div>
        <div className="tobj-row-sm">
          <div className="tobj-col1-sm">
            <div className="tyt-sub-heading-sm tobj-color-sm">
              <img src={img5} className="tobj-img-sm"  alt="Agility" />
            </div>
            <div className="each-para-div-sm">
              <div>
                Make a difference in their company’s current or future Agile transformation.
              </div>
            </div>
          </div>
          <div className="tobj-col1-sm">
            <div className="tyt-sub-heading-sm tobj-color-sm">
              <img src={img6} className="tobj-img-sm" alt="Delivery" />
            </div>
            <div className="each-para-div-sm">
              <div>
                Build, mentor and lead a team in a real-life Scrum project.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      {/* Section 4 */}
      <div className="train-your-main-sm training-overview-main-sm">
      <div className="max-width-div-sm">
        <div className="overview-cols-main-sm">
          <div className="overview-col1-sm">
            <div className="tyt-heading-main-sm">
              <div className="tyt-heading-sm tyt-heading-text-sm">What Will You Learn?</div>
            </div>
            <div className="tyt-text-sm">
              <div className="each-para-div-sm padding-bottom-para-sm">
                <div>
                  You will learn how to apply Scrum practices such as coaching your team to be highly efficient and guiding them to success. The Professional Pathway Scrum Master Course is available for all, there are no prerequisites needed in terms of your current field.
                </div>
              </div>
              <div className="each-para-div-sm">
                <div>
                  <div className="modules-covered-sm">
                    <div className="mc-heading-sm tyt-sub-heading-sm">
                      Modules Covered
                    </div>
                    <div className="items-cols-sm">
                      <div className="item-col1-sm">
                        <div>Project Initiation</div>
                        <div className="modules-covered-line-sm"><hr /></div>
                      </div>
                      <div className="item-col2-sm">
                        <div>Business Analysis</div>
                        <div className="modules-covered-line-sm"><hr /></div>
                      </div>
                    </div>
                    <div className="items-cols-sm">
                      <div className="item-col1-sm">
                        <div>Project Management</div>
                        <div className="modules-covered-line-sm"><hr /></div>
                      </div>
                      <div className="item-col2-sm">
                        <div>Scrum</div>
                        <div className="modules-covered-line-sm"><hr /></div>
                      </div>
                    </div>
                    <div className="items-cols-sm">
                      <div className="item-col1-sm">
                        <div>Scrum Roles and Responsibilities</div>
                        <div className="modules-covered-line-sm"><hr /></div>
                      </div>
                      <div className="item-col2-sm">
                        <div>Scrum Artefacts</div>
                        <div className="modules-covered-line-sm"><hr /></div>
                      </div>
                    </div>
                    <div className="items-cols-sm">
                      <div className="item-col1-sm">
                        <div>Scrum Ceremonies</div>
                        <div className="modules-covered-line-sm"><hr /></div>
                      </div>
                      <div className="item-col2-sm">
                        <div>Agile Metrics</div>
                        <div className="modules-covered-line-sm"><hr /></div>
                      </div>
                    </div>
                    <div className="items-cols-sm">
                      <div className="item-col1-sm">
                        <div>Agile Management tools</div>
                        <div className="modules-covered-line-sm"><hr /></div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="fac-course-sm tyt-sub-heading-sm">
                      Who Facilitates The Course?
                    </div>
                    <div>
                      Our training workshops are delivered by expert Agile consultants experienced in working at renowned global companies including Barclaycard, Lloyds Bank, Selfridges &amp; Vodafone.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overview-col2-sm">
            <div>
              <div className="training-dates-main-sm tyt-text-sm">
                <div className="training-dates-text-sm tyt-sub-heading-sm">
                  Training Dates
                </div>
                <div className="location-online-sm">
                  Location: online
                </div>
                <div>
                  The first three months of training is included in the cost
                </div>
                <div className="package-includes-sm">
                  (Package also includes access to work experience opportunities)
                </div>
                <div className="training-dates-button-section-sm booknow-style-sm">
                  <link media="all" rel="stylesheet" href="css/limitedOffer.css" />
                  <div className="lf-use-code-main-sm">
                    Use code <span className="lf-coupon-code-sm">AGILE50</span> for 50% off
                  </div>
                  <button className="book-place-button-sm lokem-button-sm" data-course="1">BOOK NOW</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


      {/* Section 5 */}
      <div className=" po-journey-main">
    <img src={img8} className="po-Infograph" />
</div>
    </div>
    </>
  );
};

export default ScrumMaster;
