import React from 'react';
import Marquee from 'react-fast-marquee';
import "../assets/scss/advBanner.scss";


const Advertisement = () => {
  return (
    <div className="main-adv">
    <div className="adv-main ">
    <div className="main">
      <div className="adv-col1">
        <Marquee speed={100} gradient={false}>FREE SCRUM MASTER TASTER SESSION</Marquee>
      </div>

      {/* Uncomment this block if you want to use it in the future */}
      {/* 
      <div className="adv-col2" style={{ display: 'none' }}>
        <div className="course-titles">
          <div>Scrum Master | </div>
          <div>&nbsp;Product Owner | </div>
          <div>&nbsp;Business Analyst</div>
        </div>
      </div>
      */}

      <div className="adv-col3">
        <div className="timer-main">
          <div className="timer-button">
            <div className="flex-cols ">
              <div>
                <div className="adv-time ">&nbsp;</div>
                <div className="adv-days">&nbsp;</div>
              </div>
              <div>
                <div className="adv-time ">&nbsp;</div>
                <div className="adv-days">&nbsp;</div>
              </div>
              <div>
                <div className="adv-time ">&nbsp;</div>
                <div className="adv-days">&nbsp;</div>
              </div>
              <div>
                <div className="adv-time ">&nbsp;</div>
                <div className="adv-days">&nbsp;</div>
              </div>
            </div>
          </div>
          <div className="button-main">
            <a
              className="getStarted lokem-button"
              href="https://us02web.zoom.us/meeting/register/tZAucOqgrDwoH9FzWl-HKmgdP8W5B7zKCC82"
              data-course="1"
            >
              SIGN UP
            </a>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default Advertisement;
