import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/scss/blogs/blogs.scss";
import PageBanner from "../../pages/PageBanner";
import blogPostsData from "../../assets/json/blogPosts.json"; // Import your JSON data

// Import your images
import blogimg1 from "../../assets/images/blogs/blogs1.png";
import blogimg2 from "../../assets/images/blogs2.jpg";
import blogimg3 from "../../assets/images/blogs3.jpg";
import blogimg4 from "../../assets/images/blogs/blogs4.jpg";
import blogimg5 from "../../assets/images/blogs5.png";
import blogimg6 from "../../assets/images/blogs6.jpg";
import blogimg7 from "../../assets/images/blogs7.png";
import blogimg8 from "../../assets/images/blogs/blogs8.jpeg";
import blogimg9 from "../../assets/images/blogs9.jpg";
import blogimg10 from "../../assets/images/blogs10.jpeg";
import blogimg11 from "../../assets/images/blogs11.png";

// Define an image map to associate the string keys to actual images
const imageMap = {
  blogimg1,
  blogimg2,
  blogimg3,
  blogimg4,
  blogimg5,
  blogimg6,
  blogimg7,
  blogimg8,
  blogimg9,
  blogimg10,
  blogimg11,
} as const; // 'as const' ensures the keys are literal types

// Define the interface for a single blog post
interface BlogPost {
  id: number;
  title: string;
  description: string;
  readTime: string;
  date: string;
  imageUrl: keyof typeof imageMap; // Image URL is a key of the imageMap
  link: string;
}

const Blogs = () => {
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);

  useEffect(() => {
    // Map the imageUrl in blogPostsData to actual image URL using imageMap
    const updatedBlogPosts = blogPostsData.map((post: any) => ({
      ...post,
      imageUrl: imageMap[post.imageUrl as keyof typeof imageMap], // Type assertion to inform TS that post.imageUrl is a key of imageMap
    }));

    setBlogPosts(updatedBlogPosts); // Set the updated blog posts
  }, []);

  return (
    <>
      <PageBanner section="blogs" />
      <div className="blogs-main">
        <div className="train-your-main">
          <div className="blog-welcome-text tyt-text">
            Welcome to our blog page. You'll find our writings, latest news and
            case studies here, plus the latest in all-important technology.
          </div>
        </div>

        <div className="blogs-container">
          {blogPosts.map((post, index) => (
            <div key={post.id} className="train-your-main bottom-space">
              {/* Apply alternating classes based on index */}
              <div
                className={`scrumEvents-blog ${
                  index % 2 === 0 ? "left-image" : "right-image"
                }`}
              >
                <div>
                  <img
                    className="our-3cs-image-blog"
                    src={post.imageUrl}
                    alt={post.title}
                  />
                </div>
                <div className="scrumEvent-text-blog">
                  <div className="tyt-sub-heading padding-top-bot">
                    {post.title}
                  </div>
                  <div className="each-para-div tyt-text">
                    {post.description}
                  </div>
                  <div className="date-read-button">
                    <div>
                      <div className="min-date-color">{post.readTime}</div>
                      <div className="date-color1">{post.date}</div>
                    </div>
                    <div>
                      <Link to={post.link}>
                        <button className="button-blog1 read-more lokem-button">
                          READ MORE
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Blogs;
