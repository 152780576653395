import React, { useState } from "react";
import "../assets/scss/homePage.scss";
import HomeRequest from "../pages/HomeRequest";

import S2img1 from "../assets/images/pathway-img2.jpg";
import S2img2 from "../assets/images/pathway-img1.jpg";
import S2img3 from "../assets/images/pathway-img3.jpg";
import S2img4 from "../assets/images/pathway-img4.jpg";

import S3img1 from "../assets/images/home-earn.gif";
import S3img2 from "../assets/images/home-training.gif";
import S3img3 from "../assets/images/home-mentor.gif";
import S3img4 from "../assets/images/home-cv.gif";
import S3img5 from "../assets/images/home-certified.png";
import S3img6 from "../assets/images/home-job.gif";

import S4img1 from "../assets/images/voice1.jpg";
import S4img2 from "../assets/images/voice2.jpg";
import S4img3 from "../assets/images/voice3.jpg";
import S4audio1 from "../assets/audio/tm-ar1.mp3";
import S4audio2 from "../assets/audio/tm-ar2.mp3";
import S4audio3 from "../assets/audio/tm-ar3.mp3";

import S5img1 from "../assets/images/homepage-sarah.jpg";
import S5img2 from "../assets/images/homepage-sandra.jpg";
import S5openQuote from "../assets/images/quote.jpg";
import S5closeQuote from "../assets/images/quote-bottom.jpg";
import S5rightarrow from "../assets/images/greaterthan.png";
import S5leftarrow from "../assets/images/lessthan.png";

import S6img1 from "../assets/images/logos/1.jpg";
import S6img2 from "../assets/images/logos/2.jpg";
import S6img3 from "../assets/images/logos/3.jpg";
import S6img4 from "../assets/images/logos/4.jpg";
import S6img5 from "../assets/images/logos/5.jpg";
import S6img6 from "../assets/images/logos/6.jpg";
import S6img7 from "../assets/images/logos/7.jpg";
import S6img8 from "../assets/images/logos/8.jpg";
import S6img9 from "../assets/images/logos/9.jpg";
import S6img10 from "../assets/images/logos/10.jpg";
import S6img11 from "../assets/images/logos/11.jpg";
import S6img12 from "../assets/images/logos/12.jpg";
import S6img13 from "../assets/images/logos/13.jpg";
import S6img14 from "../assets/images/logos/14.jpg";
import S6img15 from "../assets/images/logos/15.jpg";
import S6img16 from "../assets/images/logos/16.jpg";
import S6img17 from "../assets/images/logos/17.jpg";
import S6img18 from "../assets/images/logos/18.jpg";
import S6img19 from "../assets/images/logos/19.jpg";
import S6img20 from "../assets/images/logos/20.jpg";
import S6img21 from "../assets/images/logos/21.jpg";
import S6img22 from "../assets/images/logos/22.jpg";
import S6img23 from "../assets/images/logos/23.jpg";
import S6img24 from "../assets/images/logos/24.jpg";
import S6img25 from "../assets/images/logos/25.jpg";
import S6img26 from "../assets/images/logos/26.jpg";
import S6img27 from "../assets/images/logos/27.jpg";
import S6img28 from "../assets/images/logos/28.jpg";
import S6img29 from "../assets/images/logos/29.jpg";
import S6img30 from "../assets/images/logos/30.jpg";
import S6img31 from "../assets/images/logos/31.jpg";
import S6img32 from "../assets/images/logos/32.jpg";

import S7img1 from "../assets/images/phone.png";
import S7img2 from "../assets/images/email.png";
import homepage from "../assets/json/homepage.json";
import AdvBanner from "../pages/AdvBanner";

// form //
interface FormState {
  name: string;
  email: string;
  contactNumber: string;
  interest: string;
}

// Logo Slider
const logos = [
  { id: 1, src: S6img1, alt: "Brand 1" },
  { id: 2, src: S6img2, alt: "Brand 2" },
  { id: 3, src: S6img3, alt: "Brand 3" },
  { id: 4, src: S6img4, alt: "Brand 4" },
  { id: 5, src: S6img5, alt: "Brand 5" },
  { id: 6, src: S6img6, alt: "Brand 6" },
  { id: 7, src: S6img7, alt: "Brand 7" },
  { id: 8, src: S6img8, alt: "Brand 8" },
  { id: 9, src: S6img9, alt: "Brand 9" },
  { id: 10, src: S6img10, alt: "Brand 10" },
  { id: 11, src: S6img11, alt: "Brand 11" },
  { id: 12, src: S6img12, alt: "Brand 12" },
  { id: 13, src: S6img13, alt: "Brand 13" },
  { id: 14, src: S6img14, alt: "Brand 14" },
  { id: 15, src: S6img15, alt: "Brand 15" },
  { id: 16, src: S6img16, alt: "Brand 16" },
  { id: 17, src: S6img17, alt: "Brand 17" },
  { id: 18, src: S6img18, alt: "Brand 18" },
  { id: 19, src: S6img19, alt: "Brand 19" },
  { id: 20, src: S6img20, alt: "Brand 20" },
  { id: 21, src: S6img21, alt: "Brand 21" },
  { id: 22, src: S6img22, alt: "Brand 22" },
  { id: 23, src: S6img23, alt: "Brand 23" },
  { id: 24, src: S6img24, alt: "Brand 24" },
  { id: 25, src: S6img25, alt: "Brand 25" },
  { id: 26, src: S6img26, alt: "Brand 26" },
  { id: 27, src: S6img27, alt: "Brand 27" },
  { id: 28, src: S6img28, alt: "Brand 28" },
  { id: 29, src: S6img29, alt: "Brand 29" },
  { id: 30, src: S6img30, alt: "Brand 30" },
  { id: 31, src: S6img31, alt: "Brand 31" },
  { id: 32, src: S6img32, alt: "Brand 32" },
];
const images: { [key: string]: string } = {
  "homepage-sarah.jpg": S5img1,
  "homepage-sandra.jpg": S5img2,
};

interface FormData {
  name: string;
  email: string;
  contactNumber: string;
  interest: string;
}

const HomePage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [showPrevArrow, setShowPrevArrow] = useState(false); // Initially hide the prev arrow
  const [showNextArrow, setShowNextArrow] = useState(true); // Initially show the next arrow

  // Next page logic
  const nextPage = () => {
    setCurrentPage((prevPage) => {
      const newPage = (prevPage + 1) % homepage.length; // Using homepage data instead of testimonials
      if (newPage === homepage.length - 1) {
        setShowNextArrow(false); // Hide next arrow on the last page
      } else {
        setShowNextArrow(true); // Show next arrow for other pages
      }
      setShowPrevArrow(true); // Show prev arrow when not on the first page
      return newPage;
    });
  };

  // Previous page logic
  const prevPage = () => {
    setCurrentPage((prevPage) => {
      const newPage = prevPage === 0 ? homepage.length - 1 : prevPage - 1; // Handling circular navigation
      if (newPage === 0) {
        setShowPrevArrow(false); // Hide prev arrow on the first page
      } else {
        setShowPrevArrow(true); // Show prev arrow for other pages
      }
      setShowNextArrow(true); // Show next arrow when not on the last page
      return newPage;
    });
  };

  const currentTestimonial = homepage[currentPage]; // Get current testimonial

  return (
    <>
      <AdvBanner />
      <div className="homePage-main">
        <div className="top-section-main2">
          <div className="top-page-container2">
            <div className="top-page-heading2">
              We help people become agile experts through training,
              certification, mentoring and hands on job experience
            </div>
            <div className="top-page-text2">
              Individuals successfully change their career with our proven
              training program
            </div>
          </div>
          <div className="reqCBMain">
            <div className="reqCBHeading">Request more information</div>
            <div className="callBack-main">
              <HomeRequest buttonText="Book a free call" />
            </div>
          </div>
        </div>
        {/* Section 2 */}
        <div className="course-main1">
          <div className="mainDiv1">
            <div className="course-color21">
              <div className="course-style">
                <div>
                  <h2 className="course-heading">Scrum Master Training</h2>
                  <div>
                    <img
                      src={S2img1}
                      className="path-img"
                      alt="Scrum Master Training"
                    />
                  </div>
                  <div className="course-para">
                    The Scrum Master is the servant leader of the team. They
                    ensure that the team lives agile values and principles while
                    also following Scrum practices - by clearing impediments and
                    blockers they enable the team to deliver ...
                    <span className="find-more-link">Find out more</span>
                  </div>
                </div>
                <div className="pathway-btn-container">
                  <div className="lf-use-code-main">
                    Use code <span className="lf-coupon-code">AGILE50</span> for
                    50% off
                  </div>
                  <button
                    className="pathway-btn book-place-button lokem-button"
                    data-course="1"
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
            <div className="course-color21">
              <div className="course-style">
                <div>
                  <h2 className="course-heading">Business Analyst Training</h2>
                  <div>
                    <img
                      src={S2img2}
                      className="path-img"
                      alt="Business Analyst Training"
                    />
                  </div>
                  <div className="course-para">
                    Business Analysts (BA) support Product Owners with analysis,
                    addressing customers’ needs and facilitating pressing
                    dialogues. Our course teaches you how BA’s support the Scrum
                    team by utilizing the process of empiric...
                    <span className="find-more-link">Find out more</span>
                  </div>
                </div>
                <div className="pathway-btn-container">
                  <div className="lf-use-code-main">
                    Use code <span className="lf-coupon-code">AGILE50</span> for
                    50% off
                  </div>
                  <button
                    className="pathway-btn book-place-button lokem-button"
                    data-course="2"
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="course-main1">
          <div className="mainDiv1">
            <div className="course-color21">
              <div className="course-style">
                <div>
                  <h2 className="course-heading">Product Owner Training</h2>
                  <div>
                    <img
                      src={S2img3}
                      className="path-img"
                      alt="Product Owner Training"
                    />
                  </div>
                  <div className="course-para">
                    Product Owners play a vital role in a scrum team - bridging
                    business strategy and product execution, helping the Scrum
                    Team create valuable products. This course is ideal for
                    those seeking well-paying careers that involve...
                    <span className="find-more-link">Find out more</span>
                  </div>
                </div>
                <div className="pathway-btn-container">
                  <div className="lf-use-code-main">
                    Use code <span className="lf-coupon-code">AGILE50</span> for
                    50% off
                  </div>
                  <button
                    className="pathway-btn book-place-button lokem-button"
                    data-course="3"
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>

            <div className="course-color21">
              <div className="course-style">
                <div>
                  <h2 className="course-heading">Project Manager Training</h2>
                  <div>
                    <img
                      src={S2img4}
                      className="path-img"
                      alt="Project Manager Training"
                    />
                  </div>
                  <div className="course-para">
                    Project execution, control, and closure; project planning;
                    risk management; stakeholder management; and team dynamics
                    are just a few of the subjects covered in this course. Along
                    with introducing various project manageme...
                    <span className="find-more-link">Find out more</span>
                  </div>
                </div>
                <div className="pathway-btn-container">
                  <div className="lf-use-code-main">
                    Use code <span className="lf-coupon-code">AGILE50</span> for
                    50% off
                  </div>
                  <button
                    className="pathway-btn book-place-button lokem-button"
                    data-course="4"
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Section 3 */}
        <div className="section-with-gradient">
          <div className="row">
            <div className="section">
              <div>
                {" "}
                <img
                  src={S3img1}
                  alt="Earn Up to £800 Per Day"
                  className="section-img"
                />
              </div>
              <div className="section-title">Earn Up to £800 Per Day</div>
              <div className="section-info">
                Learn the fundamentals of Scrum & Agile Methodologies with our
                interactive, hands-on workshops.
              </div>
            </div>
            <div className="section">
              <div>
                {" "}
                <img
                  src={S3img2}
                  alt="Virtual & Classroom Based Training"
                  className="section-img"
                />
              </div>
              <div className="section-title">
                Virtual & Classroom Based Training
              </div>
              <div className="section-info">
                You can join us in person to learn, or virtually. We provide the
                options of both classroom and online training - meaning you can
                join us from anywhere in the world.
              </div>
            </div>
            <div className="section">
              <div>
                <img
                  src={S3img3}
                  alt="Another Section"
                  className="section-img"
                />
              </div>
              <div className="section-title">Support & Mentorship</div>
              <div className="section-info">
                You have the option to be get assigned a qualified mentor that
                will provide continuous support before, during and after your
                new career. So you’re never on your own
              </div>
            </div>
          </div>
          <div className="row">
            <div className="section">
              <div>
                <img
                  src={S3img4}
                  alt="Different Section"
                  className="section-img"
                />
              </div>
              <div className="section-title">Exam Prep & CV Support</div>
              <div className="section-info">
                Our trainers will go over mock tests and prep you for upcoming
                exams to get your qualification. We also guide you on writing
                your CV to help you with job applications.
              </div>
            </div>
            <div className="section">
              <div>
                {" "}
                <img
                  src={S3img5}
                  alt="Another Different Section"
                  className="section-img"
                />
              </div>
              <div className="section-title">
                Gain an internationally Recognised certification{" "}
              </div>
              <div className="section-info">
                Training is delivered by qualified Scrum Masters with many years
                of experience. We provide you with the knowledge to achieve an
                internationally recognised certification to help you accomplish
                your dream role.
              </div>
            </div>
            <div className="section">
              <div>
                <img src={S3img6} alt="Final Section" className="section-img" />
              </div>
              <div className="section-title">
                Practical hands on work experience provided
              </div>
              <div className="section-info">
                As part of your training you will be assigned to a real live
                project where you will experience what it is like to be a part
                of a team and implement all that you’re learning.
              </div>
            </div>
          </div>
        </div>

        {/* Section 4 */}
        <div className="testimonials">
          <div style={{ height: "603.5px" }}>
            <div className="pressPlayText">
              Press play to listen to some of our past trainees
            </div>
            <div className="homepage-testimonial">
              <div>
                <img src={S4img1} alt="Sarah" className="testimonial-image" />
              </div>
              <div>"The hard work paid off"</div>
              <div className="tm-audio-author">- Sarah</div>
              <div className="tm-audio-container">
                <audio controls>
                  <source src={S4audio1} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            </div>
            <div className="homepage-testimonial">
              <div>
                <img src={S4img2} alt="John" className="testimonial-image" />
              </div>
              <div>"Lokem changed my life"</div>
              <div className="tm-audio-author">- Danielle</div>
              <div className="tm-audio-container">
                <audio controls>
                  <source src={S4audio2} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            </div>
            <div className="homepage-testimonial">
              <div>
                <img src={S4img3} alt="Emily" className="testimonial-image" />
              </div>
              <div>"I got multiple job offers"</div>
              <div className="tm-audio-author">- Jack</div>
              <div className="tm-audio-container">
                <audio controls>
                  <source src={S4audio3} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            </div>
          </div>
        </div>

        {/* Section 5 */}
        <div className="tm-section">
          <div className="tm-container">
            {/* Testimonial Image Section */}
            <div className="tm-img-div">
              <img
                src={images[currentTestimonial.profileImage]}
                alt="Testimonial"
                className="tm-img"
              />
            </div>

            {/* Testimonial Content Section */}
            <div className="tm-content-div">
              {/* Opening Quote Image */}
              <div className="quote-img-container">
                <img src={S5openQuote} className="quote-img" alt="Quote Open" />
              </div>

              {/* Testimonial Text Content */}
              <div className="tm-content-container">
                {currentTestimonial.quote.map((paragraph, index) => (
                  <p
                    key={index}
                    className={
                      index === currentTestimonial.quote.length - 1
                        ? "testimonial-author"
                        : ""
                    }
                  >
                    {paragraph}
                  </p>
                ))}
              </div>

              {/* Closing Quote Image */}
              <div className="quote-img-container quote-img-container-bottom">
                <img
                  src={S5closeQuote}
                  className="quote-img-bottom quote-img"
                  alt="Quote Close"
                />
              </div>
            </div>
          </div>

          {/* Carousel Navigation Arrows */}
          <div className="nav-icons">
            {/* Left Arrow (Visible only after the first page) */}
            {currentPage > 0 && (
              <div className="left-arrow-container" onClick={prevPage}>
                <img
                  src={S5leftarrow}
                  className="arrow-img left-arrow"
                  alt="Previous"
                />
              </div>
            )}

            {/* Carousel Indicators */}
            <div className="carousel-indicators">
              {homepage.map((_, index) => (
                <div
                  key={index}
                  className={`carousel-indicator ${
                    currentPage === index ? "active" : ""
                  }`}
                  onClick={() => setCurrentPage(index)}
                ></div>
              ))}
            </div>

            {/* Right Arrow (Always Visible on Non-Last Pages) */}
            {currentPage < homepage.length - 1 && (
              <div className="right-arrow-container" onClick={nextPage}>
                <img
                  src={S5rightarrow}
                  className="arrow-img right-arrow"
                  alt="Next"
                />
              </div>
            )}
          </div>
        </div>

        {/* Section 6 */}
        <div className="marquee-container-homepage">
          <div>Professionals trained by Lokem work at these companies</div>
          <div className="marquee-wrapper">
            <div className="marquee-homepage">
              {logos.map((logo) => (
                <div className="logo" key={logo.id}>
                  <img src={logo.src} className="logo-img" alt={logo.alt} />
                </div>
              ))}
              {/* Repeat logos for infinite scroll */}
              {logos.map((logo) => (
                <div className="logo" key={`clone-${logo.id}`}>
                  <img src={logo.src} className="logo-img" alt={logo.alt} />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Section 7 */}
        <div className="get-in-touch">
          <div className="git-heading">Get In Touch</div>
          <div className="git-content">
            Contact us today to find out how we can help
          </div>

          <div className="callBack-main">
            <HomeRequest buttonText="Send" />
          </div>

          <div className="phone-email-content">
            <div className="git-phone-email-container">
              <div className="git-phone-content">
                <img src={S7img1} alt="Phone Icon" className="git-logo-img" />
                <span className="git-details-font">+44(0)2034881904</span>
              </div>
            </div>
            <div className="git-phone-email-container">
              <div className="git-email-content">
                <img src={S7img2} alt="Email Icon" className="git-logo-img" />
                <span className="git-details-font">info@lokem.co.uk</span>
              </div>
            </div>
          </div>
        </div>

        {/* Section 8 */}
        <div className="charity-page">
          <div className="charity-page-container">
            <div className="charity-page-heading charity-banner-heading">
              <p className="p1">
                When you make a purchase with us, you will be helping to create
                a <span className="charity-font">positive</span> change by{" "}
                <span className="charity-font">improving</span> sanitation{" "}
                <span className="charity-font">&amp; preventing illnesses</span>{" "}
                in the lives of many.
              </p>

              <p className="p2">
                A percentage of each purchase made goes towards{" "}
                <span className="charity-font">providing</span> families across
                the globe with access to clean water.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
