import React, { useState, useEffect } from 'react';
import "../assets/scss/bookACall.scss";
import { RootState, AppDispatch } from "../store/store";
import { updateFormData, setErrors, resetFormData, submitBookCalForm } from "../slices/bookCalSlice";
import { useSelector, useDispatch } from 'react-redux';

const BookCallForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { formData, errors, status, responseMessage } = useSelector((state: RootState) => state.bookCall);

  const [showResponse, setShowResponse] = useState(false);
  
    useEffect(() => {
      let timer: NodeJS.Timeout;
      if (responseMessage) {
        setShowResponse(true); // Show the response message
        timer = setTimeout(() => setShowResponse(false), 5000); // Hide after 10 seconds
      }
      return () => clearTimeout(timer); // Clean up the timer on component unmount or response change
    }, [responseMessage]);
  

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    dispatch(updateFormData({ [name]: value }));
    dispatch(setErrors({ [name]: "" })); // Clear the error when the user types
  };

  const validate = () => {
    let valid = true;
    const newErrors = { ...errors };

    // Full Name Validation
    if (!formData.name.trim()) {
      newErrors.name = "Full name is required";
      valid = false;
    }

    // Company Name Validation
    if (!formData.companyName.trim()) {
      newErrors.companyName = "Company name is required";
      valid = false;
    }

    // Email Validation
    if (!formData.email.trim()) {
      newErrors.email = "Email address is required";
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
      valid = false;
    }

    // Phone Number Validation
    if (!formData.contactNumber.trim()) {
      newErrors.contactNumber = "Phone number is required";
      valid = false;
    }

    // Job Role Validation
    if (!formData.jobRole.trim()) {
      newErrors.jobRole = "Job role is required";
      valid = false;
    }

    // Company Size Validation
    if (!formData.companySize.trim()) {
      newErrors.companySize = "Company size is required";
      valid = false;
    }

    dispatch(setErrors(newErrors));
    return valid;
  };

  const handleSubmit = () => {
    if (validate()) {
      // Dispatch the action to submit the form data
      dispatch(submitBookCalForm(formData));
    }
  };

  useEffect(() => {
    if (status === "succeeded") {
      setIsSubmitted(true);
      // Reset form data after submission
      dispatch(resetFormData());
    }
  }, [status, dispatch]);

  return (
    <div className="book-cal-main">
      <div className="tyt-text-heading tyt-heading-text">Book a call</div>
      <div className="tyt-heading-line-main">
        <hr className="tyt-heading-line" />
      </div>

      <div className="book-call-container" id="business-form">
        <div>
          <label htmlFor="fullName">Full name</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Your full name.."
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </div>

        <div>
          <label htmlFor="companyName">Company name</label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            placeholder="Your company name.."
            value={formData.companyName}
            onChange={handleChange}
          />
          {errors.companyName && <span className="error">{errors.companyName}</span>}
        </div>

        <div>
          <label htmlFor="email">Email address</label>
          <input
            type="text"
            id="email"
            name="email"
            placeholder="Your work email address.."
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </div>

        <div>
          <label htmlFor="phoneNumber">Phone number</label>
          <input
            type="text"
            id="contactNumber"
            name="contactNumber"
            placeholder="Your phone no.."
            value={formData.contactNumber}
            onChange={handleChange}
          />
          {errors.contactNumber && <span className="error">{errors.contactNumber}</span>}
        </div>

        <div>
          <label htmlFor="jobRole">Job role</label>
          <select
            id="jobRole"
            name="jobRole"
            value={formData.jobRole}
            onChange={handleChange}
          >
            <option value="">Please select</option>
            <option value="C-Suite">C-Suite</option>
            <option value="Business(head of/director)">Business (head of/director)</option>
            <option value="hr">HR</option>
            <option value="other">Other</option>
          </select>
          {errors.jobRole && <span className="error">{errors.jobRole}</span>}
        </div>

        <div>
          <label htmlFor="companySize">Company size</label>
          <select
            id="companySize"
            name="companySize"
            value={formData.companySize}
            onChange={handleChange}
          >
            <option value="">Please select</option>
            <option value="0-10">0-10</option>
            <option value="11-200">11-200</option>
            <option value="201-1000">201-1000</option>
            <option value="1000+">1000+</option>
          </select>
          {errors.companySize && <span className="error">{errors.companySize}</span>}
        </div>

        <div className="btn-main">
          <button
            type="submit"
            id="businessBtn"
            className="book-call-btn"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>

        {showResponse && (
          <div
            id="contactMsg"
            className="contactMsg"
            style={{ display: "block" }}
          >
            {responseMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default BookCallForm;
