import React, { useEffect } from "react";
import "../assets/scss/pageBanner.scss";
import data from "../assets/json/data.json";

interface SectionData {
  heading: string;
  subheading?: string; // Optional subheading
  imgPath: string;
  imgPosition: string;
}

type SectionKey = keyof typeof data; // "aboutUs" | "services" | "contactUs"

interface PageBannerProps {
  section: SectionKey; // The section prop will be one of the keys in `data`
}

const PageBanner: React.FC<PageBannerProps> = ({ section }) => {
  const jsonSourceObject: SectionData | undefined = data[section]; // Safe access to data using the section key

  useEffect(() => {
    if (jsonSourceObject) {
      const dynamicImage = require(`../assets/images/${jsonSourceObject.imgPath}`);
      document.documentElement.style.setProperty(
        "--banner-img",
        `url("${dynamicImage}")`
      );
      document.documentElement.style.setProperty(
        "--banner-img-position",
        `${jsonSourceObject.imgPosition}`
      );
    }
  }, [jsonSourceObject]);

  return (
    <div>
      <div className="page-banner">
        <div className="overlay">
          <div className="lokem-headers">{jsonSourceObject?.heading}</div>
          <div className="banner-subtitle">{jsonSourceObject.subheading}</div>
        </div>
      </div>
    </div>
  );
};

export default PageBanner;
