import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const PaymentPage = () => {
  const [clientSecret, setClientSecret] = useState(null);
  useEffect(() => {
    const fetchClientSecret = async () => {
      const response = await fetch(
        "http://localhost:8018/create-payment-intent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setClientSecret(data.clientSecret);
    };
    fetchClientSecret();
  }, []);
  if (!clientSecret) {
    return <div>Loading...</div>; // Show loading state while waiting for clientSecret
  }
  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <CheckoutForm />
    </Elements>
  );
};
export default PaymentPage;
