import React from "react";
import "../../assets/scss/blogs/scrumBenefit.scss";

import img1 from "../../assets/images/blogs/pass1.jpg";
import img2 from "../../assets/images/blogs/pass2.jpg";
import { FaFacebookF, FaLinkedin, FaTwitter } from "react-icons/fa";
import PageBanner from "../PageBanner";
import Comments from "./Comments";

const ScrumBenefit = () => {
  return (
    <div className="scrumBenefit-main">
      <PageBanner section="scrumBenefit" />
      <div className="train-your-main-scrum">
        <div className="blog-welcome-text-scrum">
          As the most popular Agile framework used in Project Management, Scrum
          focuses on completing work in small iterations, consistently
          inspecting and adapting along the way. Scrum is a simple framework
          which is relatively easy to implement. When used effectively, it can
          help to produce a high performing team that focuses on delivering
          products that are valuable to both customers and stakeholders. The
          increased visibility and continuous feedback from customers and
          stakeholders help inform the next priority in upcoming iterations.
          With an Agile team, even major changes in requirements are tackled by
          breaking larger tasks down to doable activities in order to avoid
          overwhelming the team and hindering their progress in the project.
        </div>
      </div>
      <div className="train-your-main-scrum">
        <div className="tyt-paras-scrum">
          <div className="secs-tyt-cols-scrum">
            <div className="tyt-text-scrum">
              <div className="tyt-sub-heading-scrum">
                How does Scrum compare to traditional Waterfall project
                management?
              </div>
              <div className="each-para-div-scrum">
                Most people are familiar with the Waterfall approach to project
                management whether or not they’ve heard of the term. In a
                traditional Waterfall project, work is completed chronologically
                with fixed timelines in place. The product produced at the end
                is verified by a small sample before being rolled out to
                customers. On the other hand, Scrum does not call for extensive
                upfront planning. The focus is instead shifted to completing
                work with the knowledge that is known in order to deliver a
                small piece of value to the end user. This allows real time
                feedback to be received which will guide the next steps. The
                Scrum team will then work on completing various tasks
                simultaneously in order of priority, while avoiding dependencies
                where possible.
              </div>
            </div>
            <div className="image-blog-scrum">
              <img className="other-blogs-new-scrum" src={img1} />
            </div>
          </div>
        </div>
        <div className="each-para-blog-scrum padding-top-para-scrum">
          If we think about the risks that exist at the early stages of a new
          project, it can be far greater due to the number of unknowns. When a
          new project is initiated, there are much more unknowns and there is
          greater vulnerability in terms of the project success. Some of the
          uncertainties may include the forecasted budget which is likely just
          an inaccurate estimate, and also limited understanding on what
          customers actually want. Although these risks naturally decrease as
          the project progresses, you will find the Scrum framework can protect
          against or minimise these risks. Work is not performed all at once in
          Scrum but rather in increments, so priorities and Sprints can be
          adapted as more is learned. In this way, there is less upfront
          investment of both time and money. Although this clearly demonstrates
          the disadvantages of Waterfall project planning (which also happen to
          be the advantages of Scrum), there are also plenty of other benefits
          that Scrum can bring to your team.
        </div>
      </div>

      <div className="train-your-main-scrum">
        <div className="tyt-paras-scrum">
          <div className="secs-tyt-cols-scrum">
            <div className="tyt-text-scrum">
              <div className="tyt-sub-heading-scrum">
                Communication &amp; Transparency
              </div>
              <div className="each-para-div-scrum">
                Communication is key, whether you are working collaboratively in
                a work environment or even in day-to-day life - this could not
                be more true within a Scrum team. Events are held within the
                Scrum team to help push actions ahead, discuss work in progress,
                ensure impediments are resolved, plan future sprints, get
                feedback and more. The Scrum events are key to fostering regular
                communication between the team and promoting collaboration. The
                Sprint Review is a great example of a Scrum event that
                encourages transparency. Here, the team presents the Increment
                to the Stakeholders at the end of a Sprint and they receive
                feedback on areas of improvement as well as commendation.
                Increased transparency will in turn help build trust between
                stakeholders and also customers, and can prevent
                misunderstandings or miscommunications.
              </div>
            </div>
            <div className="image-blog2-scrum">
              <img className="other-blogs-new-scrum" src={img2} />
            </div>
          </div>
        </div>
      </div>

      <div className="train-your-main-scrum">
        <div className="tyt-sub-heading-scrum">Continuous Improvement</div>
        <div className="each-para-blog-scrum">
          In today's fast-paced market, it's becoming increasingly important for
          teams to be able to deliver products quickly and efficiently. That's
          where Scrum comes in. At its core, Scrum is all about working in short
          interactions, known as Sprints. By doing so, they're able to get
          feedback from stakeholders and customers early on in the development
          process. This feedback is then used to make adjustments and
          corrections, leading to a better end product that meets customer
          needs. But Scrum isn't just about delivering products faster - it's
          also about continuous improvement. Teams using the Scrum framework are
          always looking for ways to improve their processes and deliver
          products more efficiently. This emphasis on continuous improvement
          ensures that teams are constantly learning and adapting, and are able
          to keep pace with the ever-changing demands of the market.
        </div>
        <div className="each-para-blog-scrum padding-top-para-scrum">
          In conclusion, Scrum is a powerful Agile framework that offers many
          benefits for project teams. With its focus on completing work in small
          iterations and continuously inspecting and adapting along the way,
          Scrum can help teams to become more productive and efficient. Compared
          to traditional Waterfall project management, Scrum offers increased
          transparency, better communication, and the ability to respond quickly
          to changing requirements. By using various metrics to measure value
          and predict workload capacity, Scrum teams are able to deliver
          products to market faster and more efficiently, while also ensuring
          that the end product meets customer needs. Ultimately, Scrum offers a
          flexible and adaptable framework that can help any team to become more
          successful in delivering high-quality products to their customers.
        </div>
      </div>
      <Comments />
    </div>
  );
};

export default ScrumBenefit;
