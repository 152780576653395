import React from "react";
import "../../assets/scss/Admin/addSignUp.scss";
import { useState } from "react";

const AddSignUp = () => {
  const [link, setLink] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLink(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Link Submitted: ", link);
  };

  return (
    <div className="add-signup-link-container">
      <h1>Add signup link</h1>
      <div className="signup-link-wrapper">
        <form onSubmit={handleSubmit} className="signup-link-form">
          <div className="form-group">
            <label htmlFor="signup-link">Signup link</label>
            <input
              type="url"
              id="signup-link"
              value="https://us02web.zoom.us/meeting/register/tZ0vc-yvpjgoHtN3FAv2iFl5WVHZXhYrjrfc"
              onChange={handleChange}
              placeholder="Enter signup link"
            />
          </div>
          <button type="submit" className="submit-btn">
            Add Link
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddSignUp;
