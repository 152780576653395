import React from "react";
import "../../assets/scss//blogs/implementScrum.scss";

import image1 from "../../assets/images/blogs/group1.jpg";
import image2 from "../../assets/images/blogs/group2.jpg";

import PageBanner from "../PageBanner";
import Comments from "./Comments";

const ImplementScrum = () => {
  return (
    <div className="implement-main">
      <PageBanner section="implmentScrum" />
      <div className="train-your-main-imp">
        <div className="blog-welcome-text-imp">
          If you've come this far, you probably have a rough idea of what Scrum
          is and that it's pretty popular in the world of software development.
          According to the 14th Annual State of Agile Report, approximately 58%
          of companies surveyed are using Scrum to build and release consistent
          products in an incremental format - by far the most widely used.
        </div>
        <div className="blog-welcome-text-imp top-space-imp">
          But how can we apply the Scrum framework to fields outside of
          Information Technology? Project Management is used in a variety of
          industries such as construction, media and cyber security. However,
          how do we implement Scrum in non- software environments?
        </div>
      </div>
      <div className="train-your-main-imp">
        <div className="tyt-paras-imp">
          <div className="secs-tyt-cols-imp">
            <div className="tyt-text-imp">
              <div className="tyt-sub-heading-imp">A Scrum Overview</div>
              <div className="each-para-div-imp">
                The Scrum framework is founded on empiricism; the practice of
                learning from experiences and evidence. It is an Agile
                methodology which helps teams and organisations to consistently
                inspect and improve their existing processes and outcomes using
                empirical data. A Scrum team has all the necessary individuals
                that can produce valuable work: the Scrum Master, the Product
                Owner and the Developers. The Scrum Master (SM) primarily
                focuses on promoting and supporting a successful and efficient
                Scrum process. The Product Owner (PO) defines the product vision
                and ensures the product built meets the customers needs and the
                business objectives. Finally, the Developers are responsible for
                doing the work, such as developing the product. In a
                non-software company this may mean that the roles need to be
                adapted in order to implement the Scrum method practically.
              </div>
            </div>
            <div className="image-blog-imp">
              <img className="other-blogs-new-imp" src={image1} />
            </div>
          </div>
        </div>
        <div className="each-para-blog-imp padding-top-para-imp">
          The Scrum roles within a non-software cyber security team might
          include a Scrum Master (SM), a Product Owner (PO) and a Subject Matter
          Expert (SME). SME's focus on a variety of areas such as risk and
          compliance, quality control or insider threat. These individuals
          essentially replace the developers, who are normally part of a
          software development team.
        </div>
      </div>
      <div className="train-your-main-imp">
        <div className="tyt-paras-imp">
          <div className="secs-tyt-cols-imp">
            <div className="image-blog2-imp">
              <img className="other-blogs-new-imp" src={image2} />
            </div>
            <div className="tyt-text-imp">
              <div className="tyt-sub-heading-imp">So, how does it work?</div>
              <div className="each-para-div-imp">
                Within Scrum, products are delivered incrementally over a set
                time period known as a 'Sprint'. An advantage of this is
                delivering new releases quicker with shorter feedback loops from
                stakeholders. With this in mind, let's revisit the example of
                using Scrum within a cyber security teams maintaining a banking
                app. The initial 'Sprint goal' may focus on safeguarding users
                from being hacked when logging into the app. Step one in
                achieving the Sprint Goal may be to take an audit of the current
                system, to check if it compiles with regulation and identify
                gaps. Step two could include developing prompts that allow users
                to generate unique identifiers and passwords.
              </div>
            </div>
          </div>
        </div>
        <div className="each-para-blog-imp padding-top-para-imp">
          In upcoming Sprints, the team may monitor app vulnerabilities creating
          a non functional replica space for testing ethical hacking. Following
          this, an automation system can be built to scan for bugs, incorrect or
          nonfunctional code, alerting to arising issues. Just like this, you
          can see how applying Scrum could help improve security measures
          thoroughly. With hyper- focus on smaller tasks, frequent testing and
          frequent team communication, there is greater assurance that no areas
          are neglected.
        </div>
      </div>
      <div className="train-your-main-imp">
        <div className="tyt-sub-heading-imp">
          Did Someone Say Wedding Planning?
        </div>
        <div className="each-para-blog-imp">
          Another example of a non-software use of Scrum, could even be a
          wedding for instance. The most basic but vital things needed to get
          married are perhaps rings, an ordained official, a marriage licence
          and a chosen place for the ceremony and reception - let's call this
          Version 1. Version 2 may include guests, a venue, food and drink.
          Version 3 may be an array of personal touches such as music,
          decorations, attire and so forth.
        </div>
        <div className="each-para-blog-imp padding-top-para-imp">
          'Artefacts', such as a Product Backlog could be used in the wedding
          planning (a list of work that needs completing before the day).
          Coupled with a 'Scrum Board' which contains a 'To Do', 'In Progress'
          and 'Done' section, allows tracking the progress of work until
          completion. The PO, SM and developers set designated times for
          planning meetings, 'Ceremonies,' to discuss tasks they can commit to
          over a period of time, 'Sprint'. Embracing scrum values, artefacts and
          pillars alongside the roles, allow for more collaborative and
          effective work. With the number of potential applications of agile
          methods such as Scrum, why not give it a try and apply a Scrum
          framework into your team projects at work or home?
        </div>
      </div>

      <Comments />
    </div>
  );
};

export default ImplementScrum;
