import React from "react";
import "../../assets/scss/Admin/addTrainingDates.scss";
import { useState } from "react";

const AddTrainingDates = () => {
  const [formData, setFormData] = useState({
    course: "",
    dates: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Form Data Submitted: ", formData);
  };

  return (
    <div>
      {" "}
      <div className="add-training-dates-container">
        <h1>Add training dates</h1>
        <div className="add-training-wrapper">
          <form onSubmit={handleSubmit} className="form">
            <div className="form-group">
              <label htmlFor="course">Select course</label>
              <select
                id="course"
                name="course"
                value={formData.course}
                onChange={handleChange}
              >
                <option value="">Please select your interest</option>
                <option value="SM Training">SM Training</option>
                <option value="BA Training">BA Training</option>
                <option value="PO Training">PO Training</option>
                <option value="PM Training">PM Training</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="dates">Provide dates</label>
              <input
                type="text"
                id="dates"
                name="dates"
                placeholder="Enter training dates"
                value={formData.dates}
                onChange={handleChange}
              />
            </div>
            <button type="submit" className="submit-btn">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddTrainingDates;
