import React, { useState, ChangeEvent, MouseEvent } from "react";
import "../assets/scss/traineeDetails.scss";

interface TraineeDetailsProps {
  courseName: string;
}

const TraineeDetails: React.FC<TraineeDetailsProps> = ({ courseName }) => {
  const [couponCode, setCouponCode] = useState<string>("");
  const [appliedCoupon, setAppliedCoupon] = useState<string>("");
  const [paymentType, setPaymentType] = useState<"Full Payment" | "Deposit">(
    "Full Payment"
  );

  const [totalAmount, setTotalAmount] = useState<number>(1000); // Example amount
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    trainingDates: "",
    courseName: courseName,
  });
  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    trainingDates: "",
    couponCode: "",
    courseName:"",
  });

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear errors on input change
  };

  const handleCouponChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCouponCode(e.target.value);
    setErrors({ ...errors, couponCode: "" });
  };

  const applyCoupon = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (!couponCode.trim()) {
      setErrors({ ...errors, couponCode: "Please enter a coupon code." });
      return;
    }

    // Simulate coupon validation
    if (couponCode === "DISCOUNT50") {
      setAppliedCoupon(couponCode);
      setTotalAmount((prev) => prev * 0.5); // Apply 50% discount
    } else {
      setErrors({ ...errors, couponCode: "Invalid coupon code." });
    }
  };

  const handlePaymentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPaymentType(e.target.value as "Full Payment" | "Deposit");
    setTotalAmount(e.target.value === "Deposit" ? 500 : 1000); // Example values
  };

  const validateForm = () => {
    const newErrors: typeof errors = {
      fullName: "",
      email: "",
      phoneNumber: "",
      trainingDates: "",
      couponCode: "",
      courseName:"",
    };
    let isValid = true;

    if (!formData.fullName.trim()) {
      newErrors.fullName = "Full name is required.";
      isValid = false;
    }

    if (!formData.courseName.trim()) {
      newErrors.courseName = "courseName is required.";
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      newErrors.email = "Valid email address is required.";
      isValid = false;
    }

    const phoneRegex = /^\+\d{1,4}\s\d{7,15}$/;
    if (!formData.phoneNumber.trim() || !phoneRegex.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Valid phone number is required (e.g., +44 1234567890).";
      isValid = false;
    }

    if (!formData.trainingDates) {
      newErrors.trainingDates = "Please select a training date.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      alert("Form submitted successfully!");
      // Proceed to payment or API call here
    }
  };

  return (
    <div className="trainee-main">
       <form id="payment-form" onSubmit={handleSubmit}>
         <div className="orderSummary">{courseName}</div>
      <div className="hlineMainMobile">
            <div className="leftFieldsWidthMobile"></div>
         </div>
        <div className="paymentMainSection">
          <div className="leftPaymentSection">
            <div className="traineeMainSection">
              <div className="leftPaymentHeadings">Personal Details</div>
              <input
                type="hidden"
                name="courseName"
                id="courseName"
                value="Business Analyst Training"
              />
              <input type="hidden" name="courseId" id="courseId" value="2" />
              <input type="hidden" name="amount" id="amount" value="1500" />
              <div className="traineeFieldDiv">
              <input
                  className="traineeField leftFieldsWidth"
                  type="text"
                  name="fullName"
                  id="fullName"
                  placeholder="Full Name"
                  value={formData.fullName}
                  onChange={handleInputChange}
                />
                {errors.fullName && <span className="errorText">{errors.fullName}</span>}
              </div>
              <div className="traineeFieldDiv">
              <input
                  className="traineeField leftFieldsWidth"
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email Address (info@lokem.co.uk)"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {errors.email && <span className="errorText">{errors.email}</span>}
              </div>
              <div className="traineeFieldDiv">
              <input
                  className="traineeField leftFieldsWidth"
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="Phone Number (e.g +44 1234567890)"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
                {errors.phoneNumber && <span className="errorText">{errors.phoneNumber}</span>}
              </div>
              <div className="traineeFieldDiv">
                <select
                  name="trainingDates"
                  id="trainingDates"
                  className="contactSelect interest-select"
                  value={formData.trainingDates}
                  onChange={handleInputChange}
                >
                  <option value="">Please select training date</option>
                  <option value="11th & 12th Jan 2025">11th & 12th Jan 2025</option>
                  <option value="8th & 9th Feb 2025">8th & 9th Feb 2025</option>
                </select>
                {errors.trainingDates && <span className="errorText">{errors.trainingDates}</span>}
              </div>
              <div className="hlineMain">
                <div className="hline leftFieldsWidth"></div>
              </div>
            </div>
            <div className="scheduleMain">
              <div className="leftPaymentHeadings">
                Payment Schedule
                <div className="nonRefundable">
                  This payment is non refundable
                </div>
              </div>
              <div className="typeOfPaymentDiv">
                <span>
                  <input
                    type="radio"
                    name="typeOfPayment"
                    id="fullPayment"
                    value="Full Payment"
                    className="typeOfRadioField"
                    checked={paymentType === "Full Payment"}
                    onChange={handlePaymentChange}
                  />
                  <label className="typeOfPaymentLabel fullPaymentLabel">
                    Full Payment (£1500)
                  </label>
                </span>
              </div>
              <div className="typeOfPaymentDiv">
                <span>
                  <input
                    type="radio"
                    name="typeOfPayment"
                    id="deposit"
                    value="Deposit"
                    className="typeOfRadioField"
                    checked={paymentType === "Deposit"}
                    onChange={handlePaymentChange}
                  />
                  <label className="typeOfPaymentLabel depositLabel">
                    Deposit (£375)
                  </label>
                </span>
              </div>
              <div className="hlineMain">
                <div className="hline leftFieldsWidth"></div>
              </div>
            </div>
            <div className="traineeMainSection">
              <div className="leftPaymentHeadings">Coupon</div>
              <div className="applyCouponDiv" style={{ textAlign: "left" }}>
                <div className="totalText">
                <input
                  type="text"
                  name="couponCode"
                  id="couponCode"
                  placeholder="Coupon"
                  className="couponField"
                  value={couponCode}
                  onChange={handleCouponChange}
                />
                  <input
                    type="hidden"
                    name="appliedCoupon"
                    id="appliedCoupon"
                    value={appliedCoupon}
                  />
                   {errors.couponCode && <span className="errorText">{errors.couponCode}</span>}
                </div>
                <div className="applyLinkDiv">
                  <a
                    href="javascript:void(0);"
                    id="applyCoupon"
                    onClick={applyCoupon}
                  >
                    Apply Coupon
                  </a>
                 
                  <a
                    href="javascript:void(0);"
                    id="removeCoupon"
                    style={{ display: appliedCoupon ? "block" : "none" }}
                  >
                    Remove Coupon
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="rightPaymentSection">
            <div className="includedInPurchase">
              <div className="rightPaymentHeadings"> Included In Purchase</div>
              <ul className="trainingIncludes">
                <li>
                  3 months Business analyst program
                  <br />
                  11th &amp; 12th Jan 2025 10:00 - 17:00 GMT
                </li>
                <li>3 months remote work experience in Scrum environment</li>
                <li>
                  Access to Scrum resources and training from agile coaches
                </li>
                <li>Certificate of attendance</li>
              </ul>
            </div>
            <div className="paymentAmountMain">
              <div className="amountHeading">Amount</div>
              <div className="totalPayment totalHeading">
                <div>
                  Total Amount &nbsp; <span id="totalAmountDiv">£1500</span>
                </div>
              </div>

              <div className="lf-use-code-main">
                Use code <span className="lf-coupon-code">AGILE50</span> for 50%
                off
              </div>

              <div className="makePaymentDiv">
                <button className="proceedPaymentBtn" id="submit">
                  <div className="spinner hidden" id="spinner"></div>
                  <span id="button-text lokem-button">Proceed to Payment</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TraineeDetails;
