import React, { useState } from "react";
import "../assets/scss/testimonials.scss";
import quotes from "../assets/images/quotes.png";
import Testimonial1 from "../assets/images/Testimonial/Testimonial-4.jpg";
import Testimonial2 from "../assets/images/Testimonial/Testimonial-new.jpeg";
import Testimonial3 from "../assets/images/Testimonial/Testimonials-1.jpg";
import Testimonial4 from "../assets/images/Testimonial/Testimonial-17.jpeg";
import Testimonial5 from "../assets/images/Testimonial/Testimonials-3.jpg";
import Testimonial6 from "../assets/images/Testimonial/Testimonials-17.jpeg";
import Testimonial7 from "../assets/images/Testimonial/Testimonials-6.jpg";
import Testimonial8 from "../assets/images/Testimonial/Testimonials-5.jpeg";
import Testimonial9 from "../assets/images/Testimonial/Testimonials-7.jpg";
import Testimonial10 from "../assets/images/Testimonial/Testimonials-10.jpg";
import Testimonial11 from "../assets/images/Testimonial/Testimonials-12.jpg";
import Testimonial12 from "../assets/images/Testimonial/Testimonials-11.jpg";
import Testimonial13 from "../assets/images/Testimonial/Testimonials-13.jpg";
import Testimonial14 from "../assets/images/Testimonial/Testimonials-15.jpg";
import Testimonial15 from "../assets/images/Testimonial/Testimonial-14.jpeg";
import Testimonial16 from "../assets/images/Testimonial/Testimonials-16.jpg";
import testimonials from "../assets/json/testimonials.json";
import PageBanner from "./PageBanner";
const imageMap: Record<string, string> = {
  Testimonial1: Testimonial1,
  Testimonial2: Testimonial2,
  Testimonial3: Testimonial3,
  Testimonial4: Testimonial4,
  Testimonial5: Testimonial5,
  Testimonial6: Testimonial6,
  Testimonial7: Testimonial7,
  Testimonial8: Testimonial8,
  Testimonial9: Testimonial9,
  Testimonial10: Testimonial10,
  Testimonial11: Testimonial11,
  Testimonial12: Testimonial12,
  Testimonial13: Testimonial13,
  Testimonial14: Testimonial14,
  Testimonial15: Testimonial15,
  Testimonial16: Testimonial16,
};
const Testimonials: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState<boolean[]>(
    new Array(testimonials.length).fill(false)
  );
  const handleToggleText = (index: number) => {
    const updatedExpanded = [...isExpanded];
    updatedExpanded[index] = !updatedExpanded[index];
    setIsExpanded(updatedExpanded);
  };
  return (
    <>
      <PageBanner section="testimonials" />
      <div className="testimonial-main">
        <div className="testimonials-container">
          {testimonials.map((testimonial, index) => (
            <div
              className="train-your-main-testi"
              id={`testimonial-${testimonial.id}`}
              key={testimonial.id}
            >
              <div className={`tyt-paras ${index % 2 === 0 ? "flex-dir" : ""}`}>
                <div className="secs-tyt-col1">
                  <img
                    className="our-3cs-image1"
                    src={imageMap[testimonial.image]}
                    alt={`Testimonial ${testimonial.id}`}
                  />
                </div>
                <div className="secs-tyt-col2">
                  <div className="tyt-text">
                    <div className="each-para-div-testi tyt-sub-heading-testi">
                      <img
                        className="quote-top-testi"
                        src={quotes}
                        alt="Quote"
                      />
                      <div className="offer-para1 secs-font">
                        {testimonial.text.length > 300 ? (
                          isExpanded[index] ? (
                            <>
                              {testimonial.text}
                              <span
                                className="read-more-less"
                                onClick={() => handleToggleText(index)}
                              >
                                Read Less
                              </span>
                            </>
                          ) : (
                            <>
                              {testimonial.text.slice(0, 300)}
                              {/* First 300 characters */}
                              <span className="dots">...</span>
                              <span
                                className="read-more-less"
                                onClick={() => handleToggleText(index)}
                              >
                                Read More
                              </span>
                            </>
                          )
                        ) : (
                          // Show the full text if it's less than or equal to 300 characters
                          <>{testimonial.text}</>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Testimonials;
