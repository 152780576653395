import React from "react";
import "../../assets/scss/blogs/coronavirus.scss";
import PageBanner from "../PageBanner";
import Comments from "./Comments";

const Coronavirus = () => {
  return (
    <div className="coronavirus-main">
      <PageBanner section="coronaVirus" />
      <div className="train-your-main-corona">
        <div className="tyt-paras-corona">
          <div className="secs-tyt-cols-corona">
            <div className="tyt-text-corona">
              <div className="each-para-div-corona para-text-corona">
                The Coronavirus pandemic brought about a significant change in
                various industries, especially in the ever-evolving field of
                technology. This blog explores how the pandemic has reshaped
                consumer behaviour, accelerated digital adoption, and spurred
                innovation in the tech industry in response to global
                challenges.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="train-your-main-corona">
        <div className="tyt-paras-coron flex-dir-corona">
          <div className="secs-tyt-cols-corona">
            <div className="tyt-text-corona">
              <div className="tyt-sub-heading-corona">
                Digital Darwinism in Retail
              </div>

              <div className="each-para-div-corona">
                The stark contrast between traditional and online retail became
                glaringly evident as lockdowns and restrictions swept the globe.
              </div>
              <div className="each-para-div-corona">
                High-street giant Primark witnessed a staggering drop in sales,
                going from £650 million a month to zero. In contrast,
                digital-native ASOS experienced a windfall, reporting profits
                soaring up to 329% in October 2020. This contrast highlighted
                the crucial need for a strong digital presence and effective
                e-commerce capabilities when dealing with unforeseen
                disruptions.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-corona">
        <div className="tyt-paras-corona">
          <div className="secs-tyt-cols-corona">
            <div className="tyt-text-corona">
              <div className="tyt-sub-heading-corona">
                Supply Chain Disruptions
              </div>
              <div className="each-para-div-corona">
                The pandemic exposed vulnerabilities in global supply chains,
                impacting the availability of goods for retailers. Many faced
                difficulties due to disrupted supply chains, leading to
                shortages and logistical challenges. Companies were increasingly
                turning to resilient and transparent supply chain management
                strategies, with technologies like predictive analytics and
                blockchain gaining prominence in this pursuit.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-corona">
        <div className="tyt-paras-corona">
          <div className="tyt-text-corona">
            <div className="tyt-sub-heading-corona">
              The Rise of Remote Tech
            </div>
            <div className="each-para-div-corona">
              The forced shift towards remote work became a defining feature of
              the pandemic. Platforms like Zoom experienced a meteoric rise,
              witnessing a 74% increase in shares. The demand for remote
              communication tools skyrocketed, prompting tech companies to
              innovate and adapt swiftly to meet the surge in user needs. This
              shift not only transformed the way that businesses operate but
              also fueled the evolution of collaborative technologies
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-corona">
        <div className="tyt-paras-corona">
          <div className="tyt-text-corona">
            <div className="tyt-sub-heading-corona">
              Tech's Role in Global Response
            </div>
            <div className="each-para-div-corona">
              Countries around the world turned to technology to combat the
              virus. In China, drones equipped with thermal sensors were used
              for fever detection in public spaces, while also publishing an app
              that enabled self-quarantined individuals to monitor symptoms and
              progress. Australia employed chatbots to spread accurate
              information, combat misinformation, and promptly address citizen
              queries.
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-corona">
        <div className="tyt-paras-corona">
          <div className="tyt-text-corona">
            <div className="tyt-sub-heading-corona">
              Tech's Role in the UK’s Response
            </div>
            <div className="each-para-div-corona">
              In the UK, a contact tracing app was launched by the NHS to alert
              a user if they had been in close proximity to a carrier of the
              virus. Like many other countries, the UK also utilised remote
              working tools and platforms as tech companies rolled out tools for
              remote collaboration, video conferencing, and project management.
              This was to assist the transition of businesses to remote working
              conditions.
            </div>
            <div className="each-para-div-corona">
              Data analytics and AI technologies were used to both analyse and
              visualise COVID-19 data, track infection rates, and predict
              hotspots to better inform decision-making by UK policymakers and
              Public Health Officials.
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-corona">
        <div className="tyt-paras-corona">
          <div className="tyt-text-corona">
            <div className="tyt-sub-heading-corona">Battling the Infodemic</div>
            <div className="each-para-div-corona">
              The rapid spread of misinformation, worsened by social media
              platforms, emerged as a significant challenge during the pandemic.
              False beliefs, such as the unfounded link between 5G masts and the
              virus, &ZeroWidthSpace;&ZeroWidthSpace;emphasised the necessity
              for effective information sharing and mechanisms for
              fact-checking.
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-corona">
        <div className="tyt-paras-corona">
          <div className="tyt-text-corona">
            <div className="tyt-sub-heading-corona">Conclusion</div>
            <div className="each-para-div-corona">
              As the tech industry adapts and innovates in response to the
              challenges posed by the pandemic, its resilience and ability to
              transcend various sectors become evident. The pandemic also
              highlighted the indispensable role of technology in public health
              and crisis management. Businesses that embrace agility and
              leverage technology for rapid adaptation will continue to thrive
              in this ever-evolving landscape, not just during the pandemic but
              well beyond it. The Coronavirus has served as a catalyst for
              transformative change, propelling the tech industry into a new era
              of innovation and resilience.
            </div>
          </div>
        </div>
      </div>
      <Comments />
    </div>
  );
};

export default Coronavirus;
