import React from "react";
import "../../assets/scss/blogs/aceYour.scss";
import starimg from "../../assets/images/blogs/starimage.png";
import interviewjob from "../../assets/images/blogs/interview-job.png";
import exampleinterview from "../../assets/images/blogs/example-interview.png";
import PageBanner from "../PageBanner";
import Comments from "./Comments";

const AceYour = () => {
  return (
    <div className="aceYour-main">
      <div className="train-your-main-ace">
        <PageBanner section="aceYour" />
      </div>
      <div className="train-your-main-ace">
        <div className="blog-welcome-text-ace">
          Interviews can be daunting, stressful and unpredictable. For these
          reasons alone, it is important to minimise the effect these could have
          on you by preparing in advance. As with many things in life,
          "Preparation is Key" and an interview is by no means an exception.
        </div>
        <div className="each-para-blog-ace">
          One of the ways to prepare in advance is by using a technique known as
          the STAR technique (a.k.a. Format or Method).
        </div>
      </div>
      <div className="train-your-main-ace">
        <div className="tyt-paras-ace flex-dir-ace">
          <div className="secs-tyt-col1-ace">
            <div className="tyt-text-ace">
              <div className="padding-top-para-ace">
                <span className="bold-font-ace">S</span>ituation - Start off
                with a brief description of the scenario in correlation with the
                question. Set the scene briefly and move on to the next step.
              </div>
              <div className="padding-top-para-ace">
                <span className="bold-font-ace">T</span>ask - Mention what you
                were responsible for in that situation. Try to focus on one task
                because this will help you to keep the rest of your response
                unified and in line with the narrative you are trying to create.
                Again, keep this brief.
              </div>
              <div className="padding-top-para-ace">
                <span className="bold-font-ace">A</span>ction - Elaborate on the
                steps you took to remedy that situation. This is where you want
                to spend more time by carefully describing what actions you took
                to resolve the situation by aligning it to the task stated
                earlier.
              </div>
              <div>
                <span className="bold-font-ace">R</span>esult - Share the
                positive outcome of your actions (feel free to add some data and
                figures to buttress the success of the result).
              </div>
            </div>
          </div>
          <div className="secs-tyt-col2-padding-ace">
            <div className="image-section-ace">
              <img className="sec1-image-ace" src={starimg} />
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-ace">
        <div className="tyt-paras-star-ace">
          <div className="tyt-text-star-ace">
            <div className="padding-top-para-star-ace">
              The STAR technique is a very important and helpful tool to use
              during an interview because it allows an interviewee to link their
              previous work experience to the question that is being asked in
              such a way that it tells a story of how well they have handled a
              similar issue on the job.
            </div>
            <div className="padding-top-para-star-ace">
              It also gives the interviewer an understanding of how much
              practical knowledge the interviewee has. The real value here is to
              show real, demonstrable and in-depth experience by adequately
              answering the questions that are being asked.
            </div>
            <div className="padding-top-para-star1-ace">
              This approach is fail-safe because it will help you to create a
              well-constructed response to your interviewer's question. This
              format is typically used to answer behavioural questions,
              scenario-based questions and questions that require you to tell a
              short but convincing story. As an interviewee, you want to create
              a clear picture of this story in your mind and depict it
              accordingly to your interviewer, but bear this in mind; your
              response must be concise and relevant.
            </div>
          </div>
          <div className="image-blog-ace">
            <img className="sec3-image-ace" src={interviewjob} />
          </div>
        </div>
      </div>
      <div className="train-your-main-ace">
        <div className="tyt-sub-heading-ace">Example</div>

        <div className="each-para-blog-ace">
          Now, let's attempt to answer a Scrum Master interview question using
          the STAR technique.
        </div>
        <div className="tyt-paras-ace flex-dir-ace">
          <div className="secs-tyt-col1-ace">
            <div className="tyt-text-ace">
              <div className="padding-top-para-ace">
                <div>
                  Interviewer: "Can you tell me about a time you resolved a
                  conflict in your team?"
                </div>
              </div>
              <div className="padding-top-para-ace">You: "Absolutely!"</div>
              <div className="padding-top-para-ace">
                Situation - Remember to keep this simple. Focus on one situation
                by translating your thoughts clearly into words.
              </div>
              <div className="padding-top-para-ace">
                "I recall a time in my previous role when conflict arose during
                a Sprint Planning session when the Product Owner took it upon
                themself to assign tasks to the developers in the team"
              </div>
              <div className="padding-top-para-ace">
                Task - Now, shine a spotlight on the role you played by
                highlighting it.
              </div>
            </div>
          </div>
          <div className="secs-tyt-col2-padding-ace">
            <div className="image-section-ace">
              <img className="sec2-image-ace" src={exampleinterview} />
            </div>
          </div>
        </div>

        <div className="para-divs-ace">
          "As the conflict unfolded right there, I knew I had to take immediate
          action as the Scrum Master to calm the situation down and resolve
          this."
        </div>
        <div className="para-divs-ace">
          Action - Go into more details about how you carried out the task.
        </div>
        <div className="para-divs-ace">
          "I suggested to the team that a session where the whole team revisits
          the Team Working Agreement might be a great idea which they all agreed
          to. I then went on to schedule this session for the whole team to
          attend."
        </div>
        <div className="para-divs-ace">
          {" "}
          Result - Summarise the outcome of the actions you took.
        </div>
        <div className="para-divs-ace">
          "This helped the whole team to understand their accountabilities more
          clearly which then increased team performance due to my swift action
          to nip the issue of micromanagement in the bud. After 'X' Sprints, the
          developers were able to increase their performance by 'Y%'."
        </div>
      </div>

      <div className="train-your-main-ace">
        <div className="tyt-sub-heading-ace"> Tips</div>

        <div className="para-divs-ace">
          Finally, I'll leave you with some <strong>VITAL</strong> things you
          should keep in mind when preparing to answer your interview questions.
          Surprise! It involves the use of <strong>STAR</strong>, again.
        </div>

        <div className="para-divs-ace">
          1. Another STAR acronym (not to be confused with the actual STAR
          technique) serves as a reminder to keep your response to the interview
          questions:
          <div className="text-para-ace">
            <div>
              <span className="bold-font-ace">S</span> - Streamlined
            </div>
            <div>
              <span className="bold-font-ace">T</span> - Time-boxed
            </div>
            <div>
              <span className="bold-font-ace">A</span> - Articulate
            </div>
            <div>
              <span className="bold-font-ace">R</span> - Relevant
            </div>
          </div>
        </div>
        <div className="para-divs-ace">
          The reasons for the list above are that
          <ul className="star-list-ace">
            <li>A streamlined response is a great response.</li>
            <li>
              Maintaining a time-box helps to stay on track by preventing you
              from losing the attention of your interviewer.
            </li>
            <li>
              Being able to articulate your answer shows professionalism,
              confidence and in-depth experience.
            </li>
            <li>
              Keeping your response relevant to the interviewer's question is
              the ultimate goal.
            </li>
          </ul>
        </div>
        <div className="para-divs-ace">
          2. Practise your interview scenarios in advance until you know it like
          the back of your hand.
        </div>
        <div className="para-divs-ace">
          3. If it is a video interview, make use of sticky notes by jotting
          down important words or phrases, again, using the STAR approach.
        </div>
      </div>
      <Comments />
    </div>
  );
};

export default AceYour;
