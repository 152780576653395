import React from "react";
import '../assets/scss/privacyPolicy.scss';

const PrivacyPolicy = () => {
    return (
        <div className ="privacyPolicy-main">
            <div className="privacy-banner">
                <h1 className="banner-title">privacy policy</h1>
            </div>
            <div className="privacy-body">
                <h2 className="title-text">1. Who are we?</h2>
                <p className="text">Lokem are experts in Scrum, the most widely 
                    used Agile Framework. We provide training 
                    and consulting to organizations and individuals. 
                    Our consultations and training workshops are 
                    delivered by expert Agile consultants experienced 
                    in working with various clients globally, training 
                    and coaching individuals and organizations in their 
                    transition to Scrum and Agile. Our company is registered 
                    in England at Kemp House 160, City Road, London 
                    EC1V 2NX. We are the controller of the personal 
                    information described in this notice, unless stated 
                    otherwise.</p>

                <h2 className="title-text">2. How do we collect personal information?</h2>
                <p className="privacy-text"> We collect and combine personal information from the following areas:</p>

                <h3 className="subtext">2.1 Information you provide us with</h3>
                <p className="privacy-text">You may contribute personal information to our website 
                    by filling in forms, meeting with us, communicating 
                    with us via telephone, post, email etc. or 
                    submitting information to our service or product 
                    portals.</p>

                <h3 className="subtext">2.2 Information we collect ourselves</h3>
                <p className="privacy-text">On visiting our website, we may 
                    automatically store information regarding the 
                    device you are viewing the site on and your 
                    usage of the site.</p>

                <h3 className="subtext">2.3 Information we receive from others</h3>
                <p className="privacy-text">We may collect personal information from 
                    openly available sources and other companies 
                    such as service providers, trade agents, 
                    suppliers and advertisers. For instance, 
                    within our recruitment processes, we may 
                    receive information from recruitment 
                    ervice providers, employment vetting 
                    agencies and your named referees. Job 
                    search websites and professional networking 
                    sites such as LinkedIn may be used to find 
                    prospective candidates for job vacancies.</p>

                <h2 className="title-text">3. What personal information do we collect?</h2>
                <h3 className="subtext">3.1 Training Services</h3>
                <p className="privacy-text">As Lokem provides training for users, 
                    they will have to sign an NDA/TA. Once 
                    an NDA/TA has been signed we keep a copy 
                    of it, trainees agree to not share the 
                    platform resources externally unless 
                    granted permission. Likewise, information 
                    collected from the trainee will be used 
                    as necessary and stored safely. This 
                    information will not be shared with third 
                    parties unless permission is granted by 
                    the trainee.</p>

                <h3 className="subtext">3.2 Payments</h3>
                <p className="privacy-text">We receive and save any information you enter 
                    on our website or give us in any other way. 
                    You provide such information when you make a 
                    payment, set-up a recurring card payment, process 
                    a direct debit mandate, or communicate with 
                    us for any other reason. This information may 
                    include your date of birth, name, contact 
                    and card details.</p>
                <p className="privacy-text">We will use this information in order to 
                    support payment processing, fraud detection, 
                    payment authentication and processing for 
                    future transactions. You can choose not to 
                    provide this information, although it may 
                    be needed to carry out a payment.</p>

                <h3 className="subtext">3.3 General</h3>
                <p className="privacy-text">Lokem collects your personal information, this includes:</p>
                <ul>
                    <li>Personal details (title, name,gender, telephone number, email address,address)</li>
                    <li>Performance information</li>
                    <li>IP address</li>
                    <li>Start dates and end dates</li>
                    <li>payment details/subscriptions (encrypted)</li>
                </ul>

                <h2 className="title-text">4. How do we use your personal information?</h2>
                <h3 className="subtext">4.1 Performance improvement</h3>
                <p className="privacy-text">Lokem will only use your personal data for 
                    training purposes, your health and safety, 
                    the health and safety of others, the pursuance 
                    of our legitimate interests (on the provision 
                    that these are not overridden by your rights, 
                    freedoms and interests), your consent, and/or 
                    any other purpose permitted by law.</p>
                <p className="privacy-text">Some examples are given below:</p>
                <ul>
                    <li>Conducting performance reviews, managing performance and determining performance requirements</li>
                    <li>Making arrangements for the termination of our working relationship</li>
                    <li>Dealing with any disputes involving you, or other platform members including accidents at work</li>
                    <li>Managing absence</li>
                    <li>Verifying identity</li>
                </ul>

                <h3 className="subtext" >4.2 Advertising</h3>
                <p className="privacy-text">Lokem may use third party advertisers in order 
                    to promote our business and services on our 
                    behalf across the internet and other media platforms. 
                    These third party advertisers may use cookies 
                    and other tracking technologies to record the 
                    referring web page and to measure and report on 
                    the effectiveness of such advertisements.
                </p>

                <h2 className="title-text">5. Cookies and tracking</h2>
                <h3 className="subtext">5.1 What are cookies?</h3>
                <p className="privacy-text">Cookies are small blocks of data generated 
                    onto your device when you visit a website. 
                    Many websites use cookies in order to perform 
                    different tasks, e.g. recording the number 
                    of visits on a website, remembering your 
                    preferences and search queries. Cookies do 
                    not allow other people to access your device 
                    or any personal information, unless you 
                    specifically choose to share your data.
                </p>

                <h3 className="subtext">5.2 What cookies does Lokem use?</h3>
                <p className="privacy-text">Our website may use cookies and other tracking 
                    technologies to provide you with a personalised 
                    browsing experience. These cookies can tell 
                    whether you have visited Lokem before, whether 
                    you have seen the cookie notification banner, if 
                    you have accepted our terms and conditions, which 
                    pages you have visited and how long you have stayed 
                    on certain pages.</p>
                    
                    <p className="privacy-text">This helps us to provide you with the very best 
                        personal experience when browsing by displaying 
                        content that is relevant to you. Cookies and 
                        tracking also provides statistical information 
                        that will enable us to assess and improve the 
                        quality of our content and advertising on our 
                        pages.
                    </p>

                <p className="privacy-text"><strong>The cookies that we use can be split up into the following categories:</strong></p>
                <ul>
                    <li><strong>Essential cookies:</strong>  These are 
                    cookies required in order for a website to work 
                    and they do not collect any personal information. 
                    Lokem uses essential cookies to record when users 
                    have viewed cookie notices, and whether the user has 
                    granted permission for cookie tracking (essential & 
                    non- essential).</li>

                    <li><strong>Functionality cookies:</strong> These 
                    Improve your browser experience while on our website. 
                    The cookies collected store information in order to 
                    personalise your experience by recording your preferences 
                    & past searches (search requests, Chatbot user session)</li>

                    <li><strong>Analytics cookies:</strong> These are used 
                    to track how our website is performing. We use 
                    Google Analytics to track the number of visits we 
                    receive on our website, which specific pages have been 
                    visited and for how long. This information is collected 
                    on an anonymous basis.</li>
                </ul>

                <p className="privacy-text">Error reports may also be generated to support our developers to correct any dysfunction on our site.</p>

                <h3 className="subtext">5.3 Third Party Cookies</h3>
                <p className="privacy-text">Other companies may use cookies on our website, this is 
                    known as third party cookie collection. These are 
                    particularly common through features such as Youtube 
                    embedded videos. Additional cookies may be essential 
                    in order to make these features work correctly, but 
                    some companies may also include cookies for tracking 
                    and advertising.
                </p>

                <h3 className="subtext">5.4 How can you opt out or adjust cookie settings?</h3>
                <p className="privacy-text">Should you wish to withdraw your cookies consent you 
                    can adjust your cookie settings and/or refuse cookies 
                    by adjusting your browser settings. This will 
                    inevitably render some of our site responses as 
                    ineffective.
                </p>

                <p className="privacy-text"><strong>Manage Cookies in:</strong> <a href="https://support.google.com/chrome/answer/95647" target="_blank" rel="noopener noreferrer">Google Chrome</a></p>
                <p className="privacy-text"><strong>Manage Cookies in:</strong> <a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">Safari</a></p>
                <p className="privacy-text"><strong>Manage Cookies in:</strong> <a href="https://support.microsoft.com/en-gb/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank" rel="noopener noreferrer">Microsoft Edge</a></p>
                <p className="privacy-text"><strong>Manage Cookies in:</strong> <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US" target="_blank" rel="noopener noreferrer">Firefox</a></p>
                <p className="privacy-text"><strong>Manage Cookies in:</strong> <a href="https://support.microsoft.com/en-gb/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank" rel="noopener noreferrer">Internet Explorer</a></p>

                <h3 className="subtext">5.5 Marketing</h3>
                <p className="privacy-text">We may share information with you that we deem 
                    valuable to you in relation to the services we 
                    offer, as we wouldn’t want you to miss out on 
                    invaluable opportunities. Where legally required
                    we will request your consent before contacting you. 
                    You can opt out from receiving our marketing 
                    communications at any time by contacting <strong>
                    info@lokem.co.uk </strong> or clicking on any unsubscribe 
                    options in the communications that you receive 
                    from us. We never sell, rent or distribute personal 
                    information to third parties. We may monitor as to 
                    whether or not our communications were delivered or 
                    undelivered, opened, marked as spam, and whether 
                    you unsubscribed or clicked on any link within 
                    them in order to monitor the effectiveness of our 
                    campaigns, keep our mailing lists up-to-date, manage 
                    your preferences and deliver content that is most relevant 
                    to you.
                </p>

                <h3 className="subtext">5.6 Customer support</h3>
                <p className="privacy-text">Should you contact Lokem to report any issues or 
                    make queries about our services, we will record the 
                    details of our interactions with you in our support 
                    ticket management system. We may use this intelligence 
                    to respond to your query, upgrade our existing services, 
                    research and advance new services, troubleshoot and 
                    remedy issues, monitor trends, monitor support response 
                    times and effectiveness, seek feedback, monitor 
                    customer satisfaction, and provide staff training.
                </p>

                <h3 className="subtext">5.7 Website Monitoring and Security</h3>
                <p className="privacy-text">Designed for the purpose of threat detection and 
                    prevention. Lokem may record private information 
                    as we monitor our company’s systems. Moreover, this 
                    will enable us to guarantee legal use of such 
                    systems, investigating and resolving security 
                    incidents
                </p>

                <h3 className="subtext">5.8 Accordance and Compliance</h3>
                <p className="privacy-text">Our company might also retain compliance 
                    documents and reports on the steps we have 
                    taken to auditors and authorities. Your 
                    private information can be used to enforce 
                    our terms and conditions. This will also 
                    be in order to prevent illegal activities 
                    such as export and trade sanction infringements, 
                    bribery, exploitation, fraud, corruption 
                    and modern slavery.
                </p>

                <h3 className="subtext">5.9 Enrolment</h3>
                <p className="privacy-text">Any personal information that we have 
                    gathered throughout the Lokem enrolment 
                    process may be used to assess the 
                    suitability of the candidate for present 
                    and future positions. This information 
                    may be kept to evaluate the progress 
                    and performance for job references.
                </p>

                <h3 className="subtext">5.10 Your Private Information</h3>
                <p className="privacy-text">Due to the nature of the training and 
                    enrolment process that we employ, we may 
                    share your private information with those on 
                    the training platform. The service providers 
                    and the suppliers, who manage private 
                    information concerning the course may 
                    have access to private information also.
                </p>

                <h2 className="title-text">6. Where is your personal information located?</h2>
                <p className="privacy-text">Lokem may store and process your personal 
                    information outside of the European 
                    Economic Area (EEA), which also includes 
                    countries with less stringent information 
                    protection legislation. In any event, 
                    Lokem will take necessary measures to 
                    protect your information in adherence to 
                    EEA information protection standards.
                </p>
                <p className="privacy-text">For more information please contact info@lokem.co.uk</p>

                <h3 className="subtext">6.1 How long do we keep your personal information?</h3>
                <p className="privacy-text"><strong>General</strong></p>

                <p className="privacy-text">Lokem will store your personal information for as 
                    long as required in proportion to the purpose 
                    for which this information was collected. We may 
                    also be required by law or other relevant bodies
                </p>

                <p className="privacy-text">We retain personal information for as long as 
                    necessary for the purpose for which the personal 
                    information was collected, or for such longer 
                    periods required by law, to defend our organisation 
                    or otherwise necessary.
                </p>

                <p className="privacy-text">Your information may also be stored up until the 
                    period of archive retention after which, it will 
                    be anonymised or deleted.
                </p>

                <h3 className="subtext">6.2 What rights do you have?</h3>
                <p className="privacy-text">You have the following rights in respect of your personal:</p>
                <p className="privacy-text"><strong>Access -</strong> You can request information about the personal information that we have on you <br />
                <strong>Rectification - </strong>  You can contact info@lokem.co.uk to correct or update your personal Information <br /> 
                <strong>Objection - </strong> You have the right to object to the processing of your personal information based on our legitimate interests <br />
                <strong>Restriction - </strong>You have the right to ask us to keep but stop processing your personal information <br />
                <strong>Deletion - </strong> You have the right to request that we delete your personal information <br /> 
                <strong>Portability - </strong>You can request a copy of the personal information that we have in a machine readable format <br /> 
                <strong>Withdrawal - </strong> You have the right to withdraw your permission for Lokem to continue to process your information if we process based on your consent <br /> 
                <strong>Decisions - </strong> You have the right to decide not to be subject to automated decision making and profiling <br /> 
                <strong>Complaints - </strong> If you have a complaint in respect of the way that Lokem processes your personal information, please contact the Information Commissioner’s Office at https://ico.org.uk/concerns</p>
                <p className="privacy-text">In order to process your information request, Lokem may need to verify your identity.</p>

                <h2 className="title-text">7. Links</h2>
                <p className="privacy-text">Lokem may also provide site links owned by individuals 
                    and third parties. Subsequently, Lokem cannot be held 
                    responsible for the way in which these separate bodies 
                    process your information. It is advised that you 
                    thoroughly read any relevant privacy policies and 
                    terms of use before entering your personal 
                    information into these websites.
                </p>

                <h2 className="title-text">8. Security</h2>
                <p className="privacy-text">Whilst Lokem have implemented both organisational 
                    and technical infrastructures designed to protect 
                    your personal information from accidental loss, 
                    unlawful destruction, unauthorised alteration, 
                    nauthorised disclosure and unpermitted access we 
                    cannot guarantee that your personal information will 
                    remain secure from absolutely all forms of cyber attack.
                </p>

                <h2 className="title-text">9. Contact</h2>
                <p className="privacy-text">If you have any questions or complaints about this 
                    notice or our handling of your personal information, 
                    please contact us via email to info@lokem.co.uk 
                    or via post to Kemp House 160, City Road, London EC1V 2NX
                </p>
            </div>
        </div>
    );
}

export default PrivacyPolicy;