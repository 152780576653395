import React from "react";
import "../../assets/scss/Admin/showContact.scss";
import '@fortawesome/fontawesome-free/css/all.min.css';

const ShowContact = () => {

  return (
    <div className="contact-container">
      <div className="title-bar">
        <div className="menu-icon">
          <i className="fa-solid fa-bars"></i>
        </div>
        <h1>Show contact details</h1>
      </div>
      <div className="main-page">
      </div>
    </div>
  );
};

export default ShowContact;
