import "../assets/scss/aboutPage.scss";
import "../assets/scss/aboutus-impactSection.scss";
import "../assets/scss/aboutus-founder.scss";
import "../assets/scss/aboutus-ourvision.scss";
import "../assets/scss/aboutusJourney.scss";
import img from "../assets/images/our3Cs.png";
import img2 from "../assets/images/our3Cs.png";
import image1 from "../assets/images/lola1.jpg";
import image2 from "../assets/images/kemi.jpg";
import vision from "../assets/images/vision.jpeg";
import mission from "../assets/images/aboutusS1.jpg";
import map from "../assets/images/map2.png";
import PageBanner from "./PageBanner";
import React, { useState } from "react";

const AboutUsPage = () => {
  return (
    <div className="aboutus-main">
      <PageBanner section="aboutUs" />

      {/* Our Vision Our Mission Section 2 */}

      <div className="train-your-main-about vision-mission-main">
        <div className="max-width-div-about">
          {/* Vision Section */}
          <div className="overview-cols-main">
            <div className="overview-col1 right-col1">
              <div className="our-vision-main">
                <div className="tyt-heading-main-about right-heading-main">
                  <div className="tyt-heading-about tyt-heading-text-about vision-right">
                    Our Vision
                  </div>
                  <div className="tyt-heading-line-main">
                    <hr className="tyt-heading-line-about right-line" />
                  </div>
                </div>
                <div className="tyt-text-about">
                  <div className="each-para-div-about">
                    <div>
                      Immersing individuals and organisations into the world of
                      transformational growth by delivering unrivalled bespoke
                      training programmes that will foster a culture of
                      continuous learning and agility.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="overview-col2">
              <div>
                <img
                  className="course-overview-image1"
                  src={vision}
                  alt="Our Vision"
                />
              </div>
            </div>
          </div>

          {/* Mission Section */}
          <div className="overview-cols-main">
            <div className="overview-col2 row2-col2-padding">
              <div className="only-desktop">
                <img
                  className="course-overview-image1"
                  src={mission}
                  alt="Our Mission Desktop"
                />
              </div>
            </div>
            <div className="overview-col1 left-col1">
              <div className="tyt-heading-main-about">
                <div className="tyt-heading-about tyt-heading-text-about">
                  Our Mission
                </div>
                <div className="tyt-heading-line-main">
                  <hr className="tyt-heading-line-about left-line" />
                </div>
              </div>
              <div className="tyt-text-about">
                <div className="each-para-div-about">
                  <div className="our-mission-padding">
                    Our aim is to empower and guide trainees to achieve their
                    highest potential whilst providing an innovative learning
                    experience. We are committed to delivering world-class
                    training programmes that elevate organisational and
                    individual agility, enabling them to succeed in a
                    fast-changing world.
                  </div>
                </div>
              </div>
              <div className="only-mobile">
                <img
                  className="course-overview-image1"
                  src={mission}
                  alt="Our Mission Desktop"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Our 3C's * */}

      <div className="train-your-main-about">
        <div className="tyt-paras-about">
          <div className="tyt-col1-about">
            <div className="tyt-heading-main-about">
              <div className="tyt-heading-about tyt-heading-text-about">
                Our 3 C's
              </div>
              <div className="tyt-heading-line-main-about">
                <hr className="tyt-heading-line-about" />
              </div>
            </div>
            <div className="our-3cs-image-main-about">
              <img className="our-3cs-image-about" src={img} alt="Our 3 C's" />
            </div>
          </div>
          <div className="tyt-col2-about">
            <div className="tyt-text-about">
              <div className="each-para-div-about">
                <div className="offer-para1-about">
                  We value the importance of Agile and Scrum framework and how
                  much positive impact it can have in individuals and
                  organisations, which is why our community of trainers and
                  Agile coaches are passionate and proud of Lokem’s culture that
                  focuses on our 3 C’s.
                </div>
              </div>
              <div className="each-para-div-about tyt-sub-heading-about">
                Customer centricity
              </div>
              <div className="each-para-div-about">
                <div>
                  Embracing customer feedback and creating what they need.
                </div>
              </div>
              <div className="each-para-div-about tyt-sub-heading-about">
                Commitment
              </div>
              <div className="each-para-div-about">
                <div>
                  Committed to ensuring success in understanding and
                  implementing agile in your business
                </div>
              </div>
              <div className="each-para-div-about tyt-sub-heading-about">
                Continuous learning
              </div>
              <div className="each-para-div-about">
                <div>
                  Sense of encouragement to continue to learn and improve
                  through access to the platform and other trainees to learn and
                  build long-lasting relationships.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section 4 * */}
      <div className="train-your-main-about founders-main-about">
        <div className="tyt-heading-main-about">
          <div className="tyt-heading-about tyt-heading-text-about">
            Our Founders
          </div>
          <div className="tyt-heading-line-main-about">
            <hr className="tyt-heading-line-about" />
          </div>
        </div>
        <div className="tyt-paras-about">
          <div className="founder-tyt-col1-about">
            <div className="tyt-text-about">
              <div className="each-para-div-about">
                <div className="offer-para1-about">
                  Hello! We’re Lola and Kemi, the Founders of Lokem.
                </div>
              </div>
              <div className="each-para-div-about">
                <div>
                  We both had a lightbulb moment in 2019 after we discovered the
                  abundance of opportunities in the tech space. Our careers up
                  until then took us both all over the place, in various roles
                  and across Continents, but none of those positions left us
                  feeling fulfilled. After self reflecting, we changed careers
                  and entered into project management.
                </div>
              </div>
              <div className="each-para-div-about">
                <div>
                  Upon embarking on our individual journeys, it became
                  increasingly apparent that there was a lack of awareness about
                  the various roles available and the different routes from
                  which people from non-tech backgrounds could take to get into
                  the tech space.
                </div>
              </div>
              <div className="each-para-div-about">
                <div>
                  We wanted to bring this knowledge to others and fill the gap
                  with coaching and education within our communities.
                </div>
              </div>
              <div className="quote-text-main-about">
                <div className="quote-about">“</div>
                <div className="quote-text-about">
                  “I remember feeling like we'd walked into this reality that
                  was a kept secret. The first thing we wanted to do was tell
                  everybody about this. We wanted to share how much opportunity
                  and space there was here for growth. I remember thinking, ‘How
                  have we not known about this big secret? So the first thing we
                  wanted to do was provide a bridge and increase awareness about
                  this space.” - Kemi
                </div>
              </div>
              <div className="each-para-div-about">
                <div>
                  Our driving passion is our desire to build a bridge between
                  individuals and in-demand tech positions like Scrum Masters,
                  Product Owners, and Business Analysts. We want to give others
                  who come from similar backgrounds the inspiration and
                  easy-to-follow strategy they need to fully transform their
                  lives which is why we are using our experience to build
                  programmes, spaces for collaboration, and learning tracks that
                  we wish we had when we made our career shifts. The
                  opportunities are endless, providing great earning potential
                  and great room for progression.
                </div>
              </div>
            </div>
          </div>
          <div className="founder-tyt-col2-about">
            <div className="tyt-text-about">
              <div className="our-founders-image-main-about">
                <img
                  className="our-founders-image-about"
                  src={image1}
                  alt="Lola"
                />
              </div>
              <div className="our-founders-image-main-about">
                <img
                  className="our-founders-image-about"
                  src={image2}
                  alt="Kemi"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section 5 * */}
      <div className="train-your-main-about">
        <div className="tyt-heading-main-about">
          <div className="tyt-heading-about tyt-heading-text-about">
            Lokem’s journey
          </div>
          <div className="tyt-heading-line-main-about">
            <hr className="tyt-heading-line-about" />
          </div>
        </div>
        <div className="tyt-paras-about">
          <div className="lokem-journey-col1-about">
            <div className="tyt-text-about">
              <div className="each-para-div-journey-about">
                <div className="offer-para1-about">
                  Lokem has trained over 400 delegates from over six locations
                  across the globe.
                </div>
              </div>
              <div className="each-para-div-journey-about">
                <div>
                  All our training is delivered by Lokem’s team of highly
                  qualified trainers who have a proven track record and
                  real-life experiences in Agile frameworks.
                </div>
              </div>
              <div className="each-para-div-journey-about">
                <div>
                  Our programme has been developed to provide exceptional
                  training by using a hands-on approach which allows trainees to
                  fully immerse themselves in the programme.
                </div>
              </div>
              <div className="each-para-div-journey-about">
                <div>
                  Our team of highly qualified experts will continuously serve
                  as mentors and continue to be a source of knowledge and guide
                  throughout your journey with us.
                </div>
              </div>
              <div className="each-para-div-journey-about">
                <div>
                  For bespoke training that fits around your business and staff
                  get in touch with Lokem.
                </div>
              </div>
              <div className="our-trainees-heading-about">Our Trainees</div>
              <div className="lokem-journey-image-main-about">
                <img
                  className="lokem-journey-image-about"
                  src={map}
                  alt="Lokem Journey Map"
                />
              </div>
            </div>
          </div>
          <div className="lokem-journey-col2-about">
            <div className="tyt-text-about position-abs-about">
              <div className="tyt-sub-heading-about">Currently</div>
              <ul className="over-trainees-about">
                <li>Over 160 Trainees</li>
                <li>Over 400 Trained</li>
                <li>Six different time zones</li>
                <li>
                  Countries including UK, North America, UAE, Somalia, Qatar,
                  Nigeria, Canada and more!
                </li>
                <li>Over half with PSMI, PSMII or PSPOI certification</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Section 6 * */}
      <div className="charity-page">
        <div className="charity-page-container">
          <div className="charity-page-heading charity-banner-heading">
            <p className="p1">
              When you make a purchase with us, you will be helping to create a{" "}
              <span className="charity-font">positive</span> change by{" "}
              <span className="charity-font">improving</span> sanitation{" "}
              <span className="charity-font">&amp; preventing illnesses</span>{" "}
              in the lives of many
            </p>

            <p className="p2">
              A percentage of each purchase made goes towards{" "}
              <span className="charity-font">providing</span> families across
              the globe with access to clean water.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
