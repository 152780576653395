import React from "react";
import { Link } from "react-router-dom";
import "../assets/scss/productOwner.scss";
import img1 from "../assets/images/po-training1.jpg";
import img2 from "../assets/images/myTraining3.jpeg";

import img8 from "../assets/images/po-training2.jpg";
import img9 from "../assets/images/po-journey-map.png";
//Section 3
import img4 from "../assets/images/po-expertise.png";
import img5 from "../assets/images/po-mindset.png";
import img6 from "../assets/images/po-agility.png";
import img7 from "../assets/images/po-delivery.png";
import { useNavigate } from "react-router-dom";
import AdvCoupon from "../pages/AdvCoupon";

const ProductOwner = () => {

  const navigate = useNavigate();
  return (
    <>
    <AdvCoupon/>
    <div className="productOwner-main">
     {/* Banner */}
     <div className="top-page-po">
  <div className="first-page-container-po">
    <div className="first-page-heading-po banner-heading lokem-headers-po">
      Become a Certified Product Owner
    </div>
  </div>
  <div className="training-dates-button-section-po book-btn-position-po">
    <button
      className="book-place-button-po lokem-button-po"
      data-course="3" onClick={() => navigate("/product-owner")}
    >
      BOOK NOW
    </button>
  </div>
</div>

{/* Section 2 */}
<div className="train-your-main-po training-overview-main-po">
  <div className="max-width-div-po">
    <div className="overview-cols-main-po">
      {/* Column 1 */}
      <div className="overview-col1-po">
        <div className="tyt-heading-main-po">
          <div className="tyt-heading-po tyt-heading-text-po">Training Overview</div>
        </div>
        <div className="tyt-text-po">
          <div className="each-para-div-po">
            <div>
              Study with us to become a Product Owner. Give your career a boost by embarking on a training course that employers around the world respect, value and most importantly need.
            </div>
          </div>
          <div className="each-para-div-po">
            <div>
              In studying this course you will learn product ownership within Scrum and how to maximise the value of products and increase business agility through the role of the Product Owner. Product Owners motivate Scrum Teams to deliver a high-quality product, while setting the team’s priorities and the scope for the product. Throughout the duration of the course, we instill Agile and Scrum values in our trainees, setting them on the path to unwavering success as Product Owners.
            </div>
          </div>
          <div className="each-para-div-po">
            <div>
              This training is for leaders, managers, and other professionals seeking to gain in-depth knowledge on Product Ownership and learn how to revolutionise their way of delivering products.
            </div>
          </div>
          <div className="each-para-div-po">
            <div>
              Looking for a change in career? New to Product Management? No prior IT knowledge or background? No problem! We will give you all the skills & knowledge you need to succeed.
            </div>
          </div>

          <div className="each-para-div-po increase-font-po">
            <div>
              We coach, mentor and guide you in becoming a Professional Product Owner.
            </div>
          </div>
        </div>
      </div>

      {/* Column 2 */}
      <div className="overview-col2-po">
        <div>
          <img
            className="course-overview-image1-po"
            src={img1}
            alt="Scrum Training Overview 1"
          />
        </div>
        <div>
          <img
            className="course-overview-image2-po"
            src={img2}
            alt="Scrum Training Overview 2"
          />
        </div>
      </div>
    </div>
  </div>
</div>


   {/* Section 3*/}
   <div className="tobj-main-po">
  <div className="tyt-heading-po tyt-heading-text-po tobj-heading-po">Training Objectives</div>
  <div className="tobj-sub-po">Students will learn how to:</div>
  <div className="tobj-col-main-po tyt-text-po">
    <div className="tobj-row-po">
      <div className="tobj-col1-po">
        <div className="tyt-sub-heading-po tobj-color-po">
          <img src={img4} className="tobj-img-po" alt="Expertise" />
        </div>
        <div className="each-para-div-po">
          <div>
            Become an expert in the Scrum framework and develop an understanding of the critical role the Product Owner plays in a Scrum Team.
          </div>
        </div>
      </div>
      <div className="tobj-col1-po">
        <div className="tyt-sub-heading-po tobj-color-po">
          <img src={img5} className="tobj-img-po" alt="Mindset" />
        </div>
        <div className="each-para-div-po">
          <div>
            Increase the agile transformation of your current or future employers through the correct execution of the Product Owner role.
          </div>
        </div>
      </div>
    </div>
    <div className="tobj-row-po">
      <div className="tobj-col1-po">
        <div className="tyt-sub-heading-po tobj-color-po">
          <img src={img6} className="tobj-img-po" alt="Agility" />
        </div>
        <div className="each-para-div-po">
          <div>
            Adopt a product over project mindset in the role of a Product Owner.
          </div>
        </div>
      </div>
      <div className="tobj-col1-po">
        <div className="tyt-sub-heading-po tobj-color-po">
          <img src={img7} className="tobj-img-po" alt="Delivery" />
        </div>
        <div className="each-para-div-po">
          <div>
            Learn how to deliver high quality products in real-life Scrum projects.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    

      {/* Section 4 */}
      <div className="train-your-main-po training-overview-main-po">
  <div className="max-width-div-po">
    <div className="overview-cols-main-po">
      {/* Overview Column 1 */}
      <div className="overview-col1-po">
        <div className="tyt-heading-main-po">
          <div className="tyt-heading-po-po tyt-heading-text">What Will You Learn?</div>
        </div>
        <div className="tyt-text-po">
          <div className="each-para-div-po padding-bottom-para-po">
            <div>
              By working on live projects and gaining hands-on experience you will constantly be learning and developing the essential skills of a Product Owner in order to become PSPO certified. You will gain good knowledge of product ownership, including:
            </div>
          </div>
          <div className="each-para-div-po">
            <div>
              <div className="modules-covered-po">
                <div className="mc-heading-po tyt-sub-heading-po">Modules Covered</div>
                <div> The accountabilities of the Product Owner role in Scrum </div>
                <div> The importance of a product over project mind-set </div>
                <div> How to increase business agility through the Product Owner role </div>
                <div> Effective product backlog management techniques to aid developers in producing increments of quality </div>
                <div> How to effectively communicate business strategies, the product vision, product goal and product roadmap </div>
                <div> How to write user stories with acceptance criteria </div>
                <div> The importance of Empiricism and Scrum principles </div>
                <div> To understand, apply and master the full range of Scrum Artefacts </div>
                <div> Stakeholder management and collaboration techniques </div>
                <div> Identify and implement metrics to track the progress of value and successful product delivery </div>
              </div>
              <div>
                <div className="fac-course-po tyt-sub-heading-po">Who Facilitates The Course?</div>
                <div>
                  Our training workshops are delivered by expert Agile consultants working at globally renowned
                  companies including Barclaycard, Lloyds, Selfridges &amp; Vodafone.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Overview Column 2 */}
      <div className="overview-col2-po">
        <div>
          <div className="training-dates-main-po tyt-text-po">
            <div className="training-dates-text-po tyt-sub-heading-po">Training Dates</div>
            <div className="location-online-po">Location: online</div>
            <div>Followed by a minimum of 3 months online training</div>
            <div className="package-includes-po">
              (Package also includes access to work experience opportunities)
            </div>
            <div className="training-dates-button-section-po booknow-style-po">
              <link media="all" rel="stylesheet" href="css/limitedOffer.css" />
              <div className="lf-use-code-main-po">
                Use code <span className="lf-coupon-code-po">AGILE50</span> for 50% off
              </div>
              <button className="book-place-button-po lokem-button-po" data-course="2">
                BOOK NOW
              </button>
            </div>
          </div>
        </div>
        <div className="wyl-img-main-po">
          <img
            className="course-overview-image2-po float-initial-po"
            src={img8}
            alt="Course Overview"
          />
        </div>
      </div>
    </div>
  </div>
</div>


      {/* Section 5 */}
     
      <div className=" po-journey-main">
    <img src={img9} className="po-Infograph" />
</div>
    </div>

    </>
  );
};

export default ProductOwner;
