import React, { useState } from "react";
import "../assets/scss/faq.scss";
import PageBanner from "./PageBanner";

const FAQ = () => {
  
  const faqs = [
    {
      id: 1,
      question: "What is Scrum?",
      answer: "Scrum is a software development methodology that helps teams build better software faster.",
    },
    {
      id: 2,
      question: "What is the difference between Scrum and Agile Development?",
      answer: "Agile Development is a software methodology, whereas Scrum is one of process frameworks that follows Agile.",
    },
    {
      id: 3,
      question: "Do I need to have a background in IT to be able to undergo the course?",
      answer: "No. Anyone can take the course. Some of our past candidates are from teaching backgrounds; some are from retail, HR, sales etc. All you need is a desire to learn and the dedication to see it through.",
    },
    {
      id: 4,
      question: "Can Product Owner and Scrum Master’s roles be played by the same person?",
      answer: "No, since the ownership differs. Product Owner takes care of the Product Backlog, Prioritization of User Stories, and Validation of the working product increment with the user stories allocated to the Sprint.",
    },
    {
      id: 5,
      question: "What is a burn down chart?",
      answer: "A Burn Down Chart is an instrument to track the progress of the team during a Sprint. It shows on a daily basis how much story points have been done. The chart shows if it is likely that the team is going to meet the Sprint Goal.",
    },
    {
      id: 6,
      question: "What is the difference with Kanban?",
      answer: "Kanban may look a lot like Scrum, but this is mostly because many teams use Scrum elements in their Kanban approach. Kanban is a LEAN technique that optimizes the flow of a process.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>
    <PageBanner section="FAQ" />
    <div className="faq-main">
    {/* Our Vision Our Mission Section 2 */}
    <div className="train-your-main training-overview-main">
      <div className="max-width-div-faq">
        <div className="tyt-heading-main-faq">
          <div className="tyt-heading-faq tyt-heading-text-faq">
            Frequently Asked Questions
          </div>
          <div className="heading-under-para">
            Discover answers related to scrum and how it can help you.
          </div>
        </div>
        <div className="overview-cols-main-faq">
          <div className="faq-overview-col1">
            {faqs.slice(0, 3).map((faq, index) => (
              <div key={faq.id} className="tyt-text-faq">
                <div
                  className="faq-each-para-div question-para"
                  onClick={() => toggleFAQ(index)}
                >
                  <span className="expand-collapse">
                    {activeIndex === index ? "-" : "+"}
                  </span>{" "}
                  {faq.question}
                </div>
                {activeIndex === index && (
                  <div className="faq-each-para-div hidden-text">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
          <div className="faq-overview-col2">
            {faqs.slice(3).map((faq, index) => (
              <div key={faq.id} className="tyt-text-faq">
                <div
                  className="faq-each-para-div question-para"
                  onClick={() => toggleFAQ(index + 3)}
                >
                  <span className="expand-collapse">
                    {activeIndex === index + 3 ? "-" : "+"}
                  </span>{" "}
                  {faq.question}
                </div>
                {activeIndex === index + 3 && (
                  <div className="faq-each-para-div hidden-text">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default FAQ;
