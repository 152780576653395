import React, { useState, useEffect } from "react";
import "../assets/scss/individualtraining.scss";
import video from "../assets/videos/LokemFinal01.mp4";

// Images for carousel
import image1 from "../assets/images/Earn800.png";
import image2 from "../assets/images/virtualtraining.png";
import image3 from "../assets/images/support.png";
import image4 from "../assets/images/exam.png";
import image5 from "../assets/images/certification.png";
import image6 from "../assets/images/experience.png";
/*import trainingPrograms from '../assets/json/trainingPrograms.json';*/
import benefitsData from "../assets/json/benefitsData.json";


const imageMap = {
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
} as const;

interface Benefit {
  id: number;
  imageUrl: keyof typeof imageMap; // Ensure the imageUrl matches the keys in imageMap
  alt: string;
  heading: string;
  description: string;
  section: number;
}
  
  
const IndividualTraining = () => {
  const [isSection1Visible, setIsSection1Visible] = useState(true);
  const [isMobileView, setIsMobileView] = useState(false);

  const toggleSections = () => {
    setIsSection1Visible((prev) => !prev);
  };

  // Detect mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768); // Mobile breakpoint
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Cast benefitsData to an array of Benefit
  const validatedBenefits = benefitsData.map((benefit) => ({
    ...benefit,
    imageUrl: benefit.imageUrl as keyof typeof imageMap, // Cast imageUrl to match the expected type
  })) as Benefit[];
  return (
    <div className="IndividualTraining-main">
      {/* section 1 */}
      <div className="top-page">
        <div className="first-page-container">
          <div className="first-page-heading banner-heading lokem-headers"> FAST-TRACK YOUR JOURNEY TOWARDS BREAKING INTO TECH</div>
          <div className="first-section-sub">You can successfully transition with our proven training programmes (without having to code)</div>
        </div>
      </div>
      {/* section 2 */}
      <div className="individual-train-your-main individual-lokem-intro-main">
      <div className="individual-tyt-heading-main">
        <div className="individual-tyt-heading individual-tyt-heading-text">
          Introduction to Scrum
        </div>
        <div className="courses-heading-line-main">
          <hr className="individual-tyt-heading-line1" />
        </div>
      </div>
      <div className="intro-video-container">
        <div className="intro-video-main">
          <div className="intro-video-frame">
            <video
              className="intro-video"
              controlsList="nodownload"
              controls
              playsInline
              poster="media/images/lokemVideo.png"
            >
              <source src={video} type="video/mp4" />
              Your browser does not support HTML video.
            </video>
          </div>
        </div>
      </div>
    </div>

      {/* section 3 */}
      <div className="individual-train-your-main courses-main">
      <div className="individual-tyt-heading-main">
        <div className="individual-tyt-heading individual-tyt-heading-text" id="training-program-txt">
          <div className="training-prog-txt">
            Training Programmes
            <div className="courses-heading-line-main">
              <hr className="individual-tyt-heading-line1" />
            </div>
          </div>
        </div>
      </div>
      
       {/* Row 1 */}
  <div className="individual-row individual-tyt-text" id="row1">
    {/* Column 1 */}
    <div className="individual-column pathway-btn-col">
      <div className="column-text" >
        <h2 className="individual-tyt-sub-heading ">Scrum Master Training</h2>
        <div className="individual-each-para-div">
          The Scrum Master is the servant leader of the team. They ensure that the team lives agile values and principles while also following Scrum practices - by clearing impediments and blockers they enable the team to deliver products of high quality.
        </div>
        <div className="individual-each-para-div more-padding">
          This course kicks off with an intensive two-day training course encompassing Scrum fundamentals delivered by professionals with experience in the field. If you are looking to join one of the world's most highly demanded professions then this is the course for you.
          {/* Change <span> to <a> */}
          <a href="scrumMaster" className="find-more-link" style={{ display: 'none' }}>Find out more</a>
        </div>
      </div>
      <div className="individual-pathway-btn-container">
        <a className="individual-pathway-btn individual-book-place-button individual-lokem-button find-out-more-btn" data-course="1" href="scrumMaster">
          Find out more
        </a>
      </div>
    </div>

    {/* Column 2 */}
    <div className="individual-column pathway-btn-col">
      <div className="column-text">
        <h2 className="individual-tyt-sub-heading">Business Analyst Training</h2>
        <div className="individual-each-para-div">
          Business Analysts (BA) support Product Owners with analysis, addressing customers’ needs and facilitating pressing dialogues. Our course teaches you how BA’s support the Scrum team by utilising the process of empiricism and behaviour driven development to produce increments of high value.
        </div>
        <div className="individual-each-para-div more-padding">
          Key analytical techniques of business analysis are explored and applied within a Scrum environment, which will prepare you for a career as a successful, certified Business Analyst.
          {/* Change <span> to <a> */}
          <a href="businessAnalyst" className="find-more-link" style={{ display: 'none' }}>Find out more</a>
        </div>
      </div>
      <div className="individual-pathway-btn-container">
        <a className="individual-pathway-btn individual-book-place-button individual-lokem-button find-out-more-btn" dat-course="2" href="businessAnalyst">
          Find out more
        </a>
      </div>
    </div>
  </div>

  {/* Row 2 */}
  <div className="individual-row individual-tyt-text" id="row2">
    {/* Column 1 */}
    <div className="individual-column pathway-btn-col">
      <div className="column-text">
        <h2 className="individual-tyt-sub-heading">Product Owner Training</h2>
        <div className="individual-each-para-div">
          Product Owners play a vital role in a scrum team - bridging business strategy and product execution, helping the Scrum Team create valuable products. This course is ideal for those seeking well-paying careers that involve leading the development of new products.
        </div>
        <div className="individual-each-para-div more-padding">
          We help trainees to understand the critical role of the Product Owner and successful product delivery by offering extensive training, developing their skillset to a level of excellency.
          {/* Change <span> to <a> */}
          <a href="productOwner" className="find-more-link" style={{ display: 'none' }}>Find out more</a>
        </div>
      </div>
      <div className="individual-pathway-btn-container">
        <a className="individual-pathway-btn individual-book-place-button individual-lokem-button find-out-more-btn" data-course="3" href="productOwner">
          Find out more
        </a>
      </div>
    </div>

    {/* Column 2 */}
    <div className="individual-column pathway-btn-col">
      <div className="column-text">
        <h2 className="individual-tyt-sub-heading">Project Manager Training</h2>
        <div className="individual-each-para-div">
          The purpose of this Project Manager Training is to give participants the information and abilities needed to oversee large-scale projects effectively, from the planning and development stage all the way through to the deployment and completion stage.
        </div>
        <div className="individual-each-para-div more-padding">
          The purpose of this Project Manager Training is to give participants the information and abilities.
          {/* Change <span> to <a> */}
          <a href="projectManager" className="find-more-link" style={{ display: 'none' }}>Find out more</a>
        </div>
      </div>
      <div className="individual-pathway-btn-container">
  <a
    className="individual-pathway-btn individual-book-place-button individual-lokem-button find-out-more-btn"
    href="projectManager"
    data-course="4" // Using data-course as a custom attribute
  >
    Find out more
  </a>

      </div>
    </div>
  </div>
</div>

<div className="individual-train-your-main embed-main">
      <div className="i-training-heading-main">
        <div className="i-trainingHeading i-trainingHeadingText sec-headings">
          Benefits
        </div>
        <div className="courses-heading-line-main">
          <hr className="i-trainingHeadingLine1" />
        </div>
      </div>

      <div className="row-benfits i-training-text benefits-row">
        {/* Left Arrow */}
        {!isMobileView && (
          <div className="arrow">
            <i
              style={{ fontSize: "33px" }}
              className="fa circle-icon"
              onClick={toggleSections}
            >
              &#xf104;
            </i>
          </div>
        )}

        {/* Benefits */}
        {(isMobileView
          ? validatedBenefits // Show all benefits in mobile view
          : validatedBenefits.filter(
              (benefit) => benefit.section === (isSection1Visible ? 1 : 2)
            )
        ).map((benefit) => (
          <div key={benefit.id} className="column1 benfits">
            <div className="image-benfit">
              <img
                src={imageMap[benefit.imageUrl]} // Use imageMap to get the actual image
                className="benfit-img"
                alt={benefit.alt}
              />
            </div>
            <div className="each-para-heading individual-heading">
              {benefit.heading}
            </div>
            <div className="individual-para">{benefit.description}</div>
          </div>
        ))}

        {/* Right Arrow */}
        {!isMobileView && (
          <div className="arrow">
            <i
              style={{ fontSize: "33px" }}
              className="fa circle-icon"
              onClick={toggleSections}
            >
              &#xf105;
            </i>
          </div>
        )}
      </div>
    </div>

    </div>
  );
};

    export default IndividualTraining;