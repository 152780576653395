import React from "react";
import "../../assets/scss/blogs/topfive.scss";
import PageBanner from "../PageBanner";
import Comments from "./Comments";

const TopFiveAgile = () => {
  return (
    <div className="topFiveAgile-main">
      <div className="train-your-main-topfive">
        <PageBanner section="topFiveAgile" />
      </div>
      <div className="train-your-main-topfive">
        <div className="tyt-paras-topfive flex-dir-topfive">
          <div className="secs-tyt-cols-topfive">
            <div className="tyt-text-topfive">
              <div className="tyt-sub-heading-main-topfive">
                Top five performing agile companies
              </div>
              <div className="each-para-div-topfive">
                <div>
                  In a world where the business landscape is moving
                  expeditiously, an undefiable key driver in success is agility.
                  For companies who are striving to keep ahead, the ability to
                  adapt to consistent technological advancements, customer
                  demands and market conditions requires the ability to change.
                  With leading global companies adapting this principle and
                  methodology of ‘agility’ we will examine how the top 5
                  performing agile companies in the world utilise adaptability
                  to achieve exceptional success.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-topfive">
        <div className="tyt-paras-topfive">
          <div className="secs-tyt-cols-topfive">
            <div className="tyt-text-topfive">
              <div className="tyt-sub-heading-topfive">1. Spotify</div>

              <div className="each-para-div-topfive">
                <div>
                  Spotify can serve as an excellent case study as a top
                  performing company known for their Agile working environment.
                  While Spotify has certainly utilised Scrum and Kanban
                  methodologies to drive and develop their product; their
                  unwavering intention to retain Agile practices became more
                  apparent as they expanded. As they grew, they developed a
                  unique organisational structure which became known as the
                  Spotify model to help preserve their Agile teams. For the
                  purpose of this article however, we will shift our focus from
                  this well-documented model, to another practice that reflects
                  their commitment to agility - ‘hack week’. At Spotify, all
                  projects are suspended during hack week. Instead, employees
                  collaborate in diverse teams to creatively explore innovative
                  ideas, products and projects. By embracing the disruption of
                  hack week, new ideas that generate business emerge and
                  previous issues are addressed with creative solutions. While
                  this benefits the company, it is also a fun opportunity for
                  colleagues to expand their network and learn from others. The
                  autonomy given to employees demonstrates Agility and promotes
                  the engagement and motivation of employees who are more likely
                  to return to their day to day work feeling refreshed.
                  Innovation and discovery are an essential part of remaining
                  Agile. Hence, the hack weeks at Spotify allow space for change
                  that could bring further benefit to their customers and can
                  further propel their business.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-topfive">
        <div className="tyt-paras-topfive">
          <div className="tyt-text-topfive">
            <div className="tyt-sub-heading-topfive">2. Amazon</div>

            <div className="each-para-div-topfive">
              An important Agile principle is early and continuous delivery of
              value to end users; therefore, it is important for organisations
              to be customer-centric and prioritise swift delivery to their
              users. One unique strategy to ensure that teams can accelerate and
              deliver quickly was implemented by Amazon’s former CEO, Jeff
              Bezoz. In the early days of the company, the former CEO created a
              distinct ‘two pizza’ rule, which placed a limit on the number of
              people working within a team. Similar to how Scrum prescribes that
              teams should be made up of 10 or fewer individuals, the two pizza
              rule ensures that teams are kept small enough to where the entire
              group can be fed by two pizzas. Although there may not appear to
              be a direct correlation between this concept and quick delivery,
              the two factors do in fact, go hand in hand. Smaller teams can
              reduce the potential for unproductive meetings, as team members
              are less likely to succumb to ‘groupthink’. This phenomenon can be
              observed in larger meetings, where individuals tend to agree with
              the majority opinion and become deterred from sharing their
              thoughts. Not only does collaboration and self-management get
              easier with smaller teams - the risk of miscommunication is also
              minimised. The benefits of reducing the team size are many, but at
              the centre is the idea that team size and structure does not
              inhibit progress and is in fact optimised to yield the best
              results in regards to both the product and achieving customer
              needs.
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-topfive">
        <div className="tyt-paras-topfive">
          <div className="tyt-text-topfive">
            <div className="tyt-sub-heading-topfive">3. Netflix</div>

            <div className="each-para-div-topfive">
              Netflix is the world’s sixth largest internet company, and houses
              around 12,800 employees. This household name has grown to a
              multi-billion dollar company, and to manage the growth, changes
              and demands of this Netflix uses the scrum framework. This helps
              them to stay ahead of the curve, be immediately responsive to the
              needs of their consumers and get things done as quickly as
              possible. The core values and valued behaviours of Netflix appear
              to be quite similar to scrum - namely courage, integrity, and
              encouraging decision-making by employees. Strikingly, the one
              thing that Netflix explicitly states is that they avoid rules, but
              encourage freedom and responsibility. Whilst this may seem like a
              wild and lawless concept, making this work requires discipline and
              trust. A metaphor Netflix uses to illustrate this is “picking up
              the trash at home” - something that is everyone’s responsibility,
              no matter the task, and not just someone’s else’s job. This is
              said to create a sense of accountability and ownership across the
              team. Netflix acknowledges that the sense of having the freedom to
              prosper and make bold decisions in the workplace is an
              experience/privilege that can be lost when working for bigger
              companies. They also acknowledge that freedom isn’t just doing
              whatever you want. What they do value, are employees who have the
              freedom to make the best decision for the company - and so, they
              are left with a wealth of resources to do so as well as the
              accountability for the impact of their decisions.
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-topfive">
        <div className="tyt-paras-topfive">
          <div className="tyt-text-topfive">
            <div className="tyt-sub-heading-topfive">4. Microsoft</div>

            <div className="each-para-div-topfive">
              Another organisation that has transformed how it works with the
              power of Agile over the recent years is Microsoft. Microsoft Teams
              is arguably one of the best collaboration software tools around
              and was birthed by Agile practices - garnering even more
              popularity during lockdown and the rise of remote work. Under the
              leadership of their CEO Satya Nadella, agile methodologies such as
              Scrum and DevOps had directly impacted Teams’ ability to quickly
              iterate.
            </div>
            <div className="each-para-div-topfive">
              An important Agile principle is early and continuous delivery of
              value to end users; therefore, it is important for organisations
              to be customer-centric and prioritise swift delivery to their
              users. One unique strategy to ensure that teams can accelerate and
              deliver quickly was implemented by Amazon’s former CEO, Jeff
              Bezoz. In the early days of the company, the former CEO created a
              distinct ‘two pizza’ rule, which placed a limit on the number of
              people working within a team. Similar to how Scrum prescribes that
              teams should be made up of 10 or fewer individuals, the two pizza
              rule ensures that teams are kept small enough to where the entire
              group can be fed by two pizzas. Although there may not appear to
              be a direct correlation between this concept and quick delivery,
              the two factors do in fact, go hand in hand. Smaller teams can
              reduce the potential for unproductive meetings, as team members
              are less likely to succumb to ‘groupthink’. This phenomenon can be
              observed in larger meetings, where individuals tend to agree with
              the majority opinion and become deterred from sharing their
              thoughts. Not only does collaboration and self-management get
              easier with smaller teams - the risk of miscommunication is also
              minimised. The benefits of reducing the team size are many, but at
              the centre is the idea that team size and structure does not
              inhibit progress and is in fact optimised to yield the best
              results in regards to both the product and achieving customer
              needs.
            </div>
            <div className="each-para-div-topfive">
              Microsoft stated that the agile transformation journey was
              stepping away from bureaucracy - and with the adoption of the
              technical practices came the adoption of the agile mindset
              throughout the entire organisation, from the bottom up.
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-topfive">
        <div className="tyt-paras-topfive">
          <div className="tyt-text-topfive">
            <div className="tyt-sub-heading-topfive">5. Tesla</div>

            <div className="each-para-div-topfive">
              Tesla, Inc. is known for its innovative and iterative approach to
              developing electric vehicles (EVs) and other products. This
              approach involves continuous improvement and development of its
              technology and products through a series of incremental changes
              and updates. Here are some key aspects of Tesla's iterative
              approach. Tesla has been a pioneer in using OTA software updates
              to enhance the features and capabilities of its vehicles allowing
              it to iterate quickly based on user feedback and changing market
              conditions all without visiting a service centre. As several
              models of electric cars were released each new model incorporates
              lessons learned from previous ones and introduces new technologies
              and design improvements. Tesla’s Autopilot system is a notable
              example of iterative development. It started as a driver-assist
              feature and has evolved over time with regular updates to become
              more capable. The Full Self-Driving (FSD) package, although not
              fully autonomous yet, has also seen iterative improvements. The
              construction of massive production facilities, or "Gigafactories,"
              all over the world to create batteries and vehicles on a greater
              scale, is another example of Tesla's agility. Tesla's iterative
              approach is characterised by its willingness to adapt, learn from
              experience, and continuously update its products and technology.
              This approach has allowed Tesla to stay at the forefront of the
              electric vehicle industry and drive innovation in the automotive
              sector.
            </div>
            <div className="each-para-div-topfive">
              These companies continue to pioneer and steer in a rapidly
              evolving business landscape by embracing a customer centred
              approach whilst cultivating collaboration and championing change.
              Their success serves as a testament to the power of agility in
              today’s competitive world. Businesses of all sizes must consider
              and incorporate agile methodologies in their operations to thrive
              and keep pace with the evolving demands of the business market.
            </div>
          </div>
        </div>
      </div>

      <Comments />
    </div>
  );
};

export default TopFiveAgile;
