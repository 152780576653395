import React from "react";
import "../../assets/scss/blogs/userStory.scss";
import img2 from "../../assets/images/blogs/multiple.png";
import img3 from "../../assets/images/blogs/userStory.png";
import img4 from "../../assets/images/blogs/epic-banner.png";
import PageBanner from "../PageBanner";
import Comments from "./Comments";
const UserStory = () => {
  return (
    <div className="userStory-main">
      <PageBanner section="userStory" />
      <div className="train-your-main-user">
        <div className="each-para-div-user tyt-text-user first-para-user">
          If you have ever worked on a large project, you’ll know how daunting
          it is to plan how to execute all the small details to lead the project
          to success. In an Agile project, work is broken down into more
          practical tasks, so that the Scrum team can deliver work each Sprint.
          Large amounts of work, known as Epics, are broken down into smaller
          tasks or User Stories, which help the team understand what needs to be
          done to meet the Sprint Goal.
        </div>
      </div>
      <div className="train-your-main-user">
        <div className="tyt-paras-user">
          <div className="secs-tyt-col1-user">
            <div className="tyt-text-user">
              <div className="tyt-sub-heading-user">What is an Epic?</div>
              <div className="each-para-div-user">
                <div>
                  An Epic is essentially the 'Big Idea' of the project. For
                  instance, one could consider spring cleaning an Epic, since
                  it's an event with multiple layers which cannot all be
                  completed simultaneously. That's why we break it down into
                  User Stories to make it manageable.
                </div>
              </div>
            </div>
          </div>
          <div className="secs-tyt-col2-user">
            <div className="tyt-text-user">
              <div>
                <img className="our-3cs-image1-user" src={img2} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-user">
        <div className="tyt-paras-user">
          <div className="tyt-text-user">
            <div className="tyt-sub-heading-user">What is a User Story?</div>
            <div className="each-para-div-user">
              A User Story derives from an Epic and is a simplified requirement
              that can be achieved in a Sprint. So, if the Epic is spring
              cleaning, then some User Stories could be to store winter clothes,
              wash all the linen and to arrange the kitchen pantry. In a Scrum
              context, the User Stories would relate to a specific functionality
              required for the product.
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-user">
        <div className="tyt-paras-user flex-dir-user">
          <div className="secs-tyt-col1-user">
            <div className="tyt-text-user">
              <div className="tyt-sub-heading-user">
                How do we go from an Epic to a User Story?
              </div>
              <div className="each-para-div-user margin-bottum-user">
                The Product Owner plays a pivotal role in breaking Epics down
                into User Stories. Once all the Epics have been written down and
                the required features presented, the Product Owner then
                determines which functionalities are necessary for the features
                to work. These become the User Stories. These User Stories will
                then be added to the Backlog and those considered most important
                by the Product Owner will be worked on and delivered in the
                following Sprint. Hence, the Backlog is a visual display of a
                prioritised list of Epics and User Stories.
              </div>
              <div className="each-para-div-user para-margin-user">
                Some Product Owners may choose not to create User Stories for
                Epics that are not considered a priority and will instead plan
                the required features once necessary.
              </div>
            </div>
          </div>
          <div className="secs-tyt-col2-padding-user">
            <div className="tyt-text-user">
              <div>
                <img className="sec1-image-user" src={img3} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-user">
        <div className="tyt-paras-user">
          <div className="secs-tyt-col1-user">
            <div className="tyt-text-user">
              <div className="tyt-sub-heading-user">
                The Need for Decomposition
              </div>
              <div className="each-para-div-user">
                <div>
                  Breaking Epics down into User Stories helps Scrum Teams
                  understand what functionalities are required to be delivered
                  in greater detail at the end of each Sprint. With the clear
                  and prioritised backlog as guidance, team members can work
                  collaboratively and productively to ensure each User Story is
                  completed one by one in a clear and organised system. So, the
                  next time you have a big project planned, think of how you can
                  break down the big components into more manageable ones to
                  execute it smoothly.
                </div>
              </div>
            </div>
          </div>
          <div className="secs-tyt-col2-padding-user">
            <div className="tyt-text-user">
              <div>
                <img className="sec2-image-user" src={img4} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Comments />
    </div>
  );
};

export default UserStory;
