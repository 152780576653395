import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../store/store";
import {
  updateFormData,
  setErrors,
  submitContactForm,
  updateResponseMessage,
} from "../slices/contactSlice";
import "../assets/scss/contactus.scss";
import PageBanner from "./PageBanner";

const ContactForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { formData, errors, responseMessage, status } = useSelector(
    (state: RootState) => state.contactForm
  );

  const [showResponse, setShowResponse] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (responseMessage) {
      setShowResponse(true);
      timer = setTimeout(() => {
        setShowResponse(false);
        dispatch(updateResponseMessage("")); // Clear response after 5 seconds
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [responseMessage, dispatch]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    // Ensure userType is always set to 'individual' when updating form data
    dispatch(updateFormData({ [name]: name === 'userType' ? 'individual' : value }));

    // Clear errors for the specific field if any
    if (errors[name as keyof typeof errors]) {
      dispatch(setErrors({ [name]: "" }));
    }
  };

  const validateForm = () => {
    const newErrors: typeof errors = {
      name: "",
      email: "",
      contactNumber: "",
      course: "",
      userType: "", // Ensure userType is handled but will always be 'individual'
    };

    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Valid email is required.";
    if (!formData.contactNumber.trim() || !/^\d+$/.test(formData.contactNumber))
      newErrors.contactNumber = "Valid phone number is required.";
    if (!formData.course.trim()) newErrors.course = "Please select a course.";
    // No need to check for userType as it's always 'individual'
    
    dispatch(setErrors(newErrors));
    return Object.values(newErrors).every((error) => !error);
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      dispatch(submitContactForm(formData));
    }
  };

  return (
    <div className="contact-us">
      <PageBanner section="contactUs" />
      <div className="train-your-main" style={{ background: "#fff" }}>
        <div className="left-and-right">
          <div className="left-main">
            <div className="total-left">
              <div className="heading-left">We'd love to hear from you!</div>
              <div className="left-para">
                <div>Want a change in career?</div>
                <div>Want to upskill your workers?</div>
                <div>Drop us a call for more information.</div>
                <div>We’re delighted to answer all your questions.</div>
              </div>
              <div className="left-para">
                <div className="phone-text">
                  <span className="left-space">+44(0)2034881904</span>
                </div>
                <div className="mail-text">
                  <span className="left-space">info@lokem.co.uk</span>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-right">
            <div className="drop-msg-text">Drop us a message</div>
            <div className="form-container" id="contact-form">
              {/* Name Input */}
              <div>
                <label htmlFor="name">Full Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Full name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && <div className="error">{errors.name}</div>}
              </div>

              {/* Email Input */}
              <div>
                <label htmlFor="email">Email address</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Your email address"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <div className="error">{errors.email}</div>}
              </div>

              {/* Phone Number Input */}
              <div>
                <label htmlFor="contactNumber">Phone number</label>
                <input
                  type="text"
                  id="contactNumber"
                  name="contactNumber"
                  placeholder="Your phone number"
                  value={formData.contactNumber}
                  onChange={handleChange}
                />
                {errors.contactNumber && (
                  <div className="error">{errors.contactNumber}</div>
                )}
              </div>

              {/* User Type Input (Not needed anymore as userType is always 'individual') */}
              {/* <div>
                <label htmlFor="userType">User Type</label>
                <select
                  name="userType"
                  id="userType"
                  value={formData.userType}
                  onChange={handleChange}
                >
                  <option value="">Please select your user type</option>
                  <option value="Business">Business</option>
                  <option value="individual">individual</option>
                </select>
                {errors.userType && <div className="error">{errors.userType}</div>}
              </div> */}

              {/* Course Select */}
              <div>
                <label htmlFor="course">Course</label>
                <select
                  name="course"
                  id="course"
                  value={formData.course}
                  onChange={handleChange}
                >
                  <option value="">Please select your interest</option>
                  <option value="Scrum Master">Scrum Master Training</option>
                  <option value="Business Analyst">
                    Business Analyst Training
                  </option>
                  <option value="Product Owner">Product Owner Training</option>
                </select>
                {errors.course && <div className="error">{errors.course}</div>}
              </div>

              {/* Submit Button */}
              <div className="btn-main">
                <button
                  className="lokem-button"
                  onClick={handleSubmit}
                  disabled={status === "loading"}
                >
                  {status === "loading" ? "Submitting..." : "Submit"}
                </button>
              </div>

              {/* Response Message */}
              {showResponse && (
                <div id="contactMsg" className="contactMsg">
                  {responseMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
