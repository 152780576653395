import React from "react";
import "../../assets/scss/blogs/comments.scss";

import readBlog from "../../assets/images/blogs/readBlog.png";
import { FaFacebookF, FaLinkedin, FaTwitter } from "react-icons/fa";

const Comments = () => {
  return (
    <div className="main-comments">
      <div className="train-your-main-comments">
        <div className="row-comments" id="row1">
          <div className="coloumn-comments">
            <div>
              <img
                className="other-blogs-img-comments"
                src={readBlog}
                id="prevPostImg"
                alt="Previous Post"
              />
            </div>
            <div className="prev-next-post-comments" id="prevPostText">
              &lt; Previous post
            </div>
            <div className="post-name-comments" id="prevPostTitle">
              Beat Procrastination And Pass Your PSM1 In No Time
            </div>
          </div>

          <div className="coloumn-comments">
            <div>
              <div className="rate-article-text-comments">
                Rate this Article
              </div>
              <div>
                <div className="rating-comments">
                  <div id="ro-rating-main">
                    <div></div>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="rating-details-text-comments">
                <span id="curRating">0</span> from <span id="noRatings">0</span>{" "}
                ratings
              </div>
            </div>
            <div className="share-your-network-comments">
              <div className="social-text-comments">
                Share with your network
              </div>
              <div>
                <ul className="blog-social-media-icons-comments">
                  <li>
                    <a
                      href="https://www.facebook.com/lokem/"
                      target="_blank"
                      rel="noopener noreferrer"
                      id="facebookUrl"
                    >
                      <FaFacebookF />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/lokemuk"
                      target="_blank"
                      rel="noopener noreferrer"
                      id="twitterUrl"
                    >
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://LinkedIn.com/lokemuk"
                      target="_blank"
                      rel="noopener noreferrer"
                      id="linkedinUrl"
                    >
                      <FaLinkedin />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="coloumn-comments">
            <div>
              <div>
                <img
                  className="other-blogs-img-comments"
                  src={readBlog}
                  id="nextPostImg"
                  alt="Next Post"
                />
              </div>
              <div className="prev-next-post-comments" id="nextPostText">
                Next post &gt;
              </div>
              <div className="post-name-comments" id="nextPostTitle">
                Beat Procrastination And Pass Your PSM1 In No Time
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="train-your-main-comments">
        <div className="side-header-comments">
          <div className="comment-text-head-comments">Comments</div>
          <div className="comment-icon-comments">&#128172;</div>
        </div>
        <div id="comments-main">
          <div id="hrLine">
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comments;
