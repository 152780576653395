import React from "react";
import "../../assets/scss/Admin/showCoupon.scss";
import '@fortawesome/fontawesome-free/css/all.min.css';

const ShowCoupon = () => {
  const coupons = [
    {
      id: 1,
      CouponDesc: "Scrum master coupon",
      CouponCode: "Lokem300",
      Amount: 300,
      Validity: "",
      PostDate: "2022-06-01 12:02:48",
      CourceId: 1,
      Currency: "gbp",
    },
    {
      id: 2,
      CouponDesc: "Business analyst coupon",
      CouponCode: "LBA500",
      Amount: 500,
      Validity: "",
      PostDate: "2022-06-01 12:02:48",
      CourceId: 2,
      Currency: "gbp",
    },
  ];

  return (
    <div className="coupon-container">
      <div className="title-bar">
        <div className="menu-icon" >
        <i className="fa-solid fa-bars" ></i>
        </div>
        <h1>Show coupon</h1>
      </div>
      <table className="coupon-table">
        <thead>
          <tr>
            <th>CouponId</th>
            <th>CouponDesc</th>
            <th>CouponCode</th>
            <th>Amount</th>
            <th>Validity</th>
            <th>PostDate</th>
            <th>CourceId</th>
            <th>Currency</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {coupons.map((coupon) => (
            <tr key={coupon.id}>
              <td>{coupon.id}</td>
              <td>{coupon.CouponDesc}</td>
              <td>{coupon.CouponCode}</td>
              <td>{coupon.Amount}</td>
              <td>{coupon.Validity}</td>
              <td>{coupon.PostDate}</td>
              <td>{coupon.CourceId}</td>
              <td>{coupon.Currency}</td>
              <td>
              <button className="edit-btn">
                <i className="fa-regular fa-pen-to-square"></i>
                </button>
                <button className="delete-btn">
                <i className="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ShowCoupon;
