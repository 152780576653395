import React, { useState, useEffect } from "react";
import "../assets/scss/header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import headerimg from "../assets/images/logo-black.jpg";

interface SubMenuItem {
  label: string;
  link: string;
}

interface MenuItem {
  label: string;
  link?: string; // Button items may not have a link
  subMenu?: SubMenuItem[];
  button?: boolean;
}

const Header: React.FC = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);

  useEffect(() => {
    fetch("/menu.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data: { menuItems: MenuItem[] }) => {
        setMenuItems(data.menuItems);
      })
      .catch((error) => console.error("Error fetching menu data:", error));
  }, []);

  //const location = useLocation();
  const excludedRoutes = [
    "/adminLogin",
    "/welcome",
    "/trainees",
    "/showCoupon",
    "/trainingDates",
    "/showContact",
  ];

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const toggleDropdown = (index: number) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  return (
    <div className="header-top-main">
      <header>
        <div className="header-container">
          <div className="logo-container">
            <img src={headerimg} alt="Lokem" className="logo-img" />
          </div>
          <div className="menu-container">
            <div className="bars-icon" id="bars-icon" onClick={toggleMenu}>
              <i className="fa fa-bars" aria-hidden="true"></i>
            </div>
          </div>
          <div className="menu-items-container">
            <ul className={`menu-items ${menuVisible ? "open" : ""}`}>
              {menuItems.map((item, index) =>
                item.button ? (
                  <li key={index}>
                    <button className="login-button login-click lokem-button">
                      {item.label}
                    </button>
                  </li>
                ) : (
                  <li
                    key={index}
                    className={`${
                      item.label === "Our Trainings" ? "dropup" : "" // Apply dropup class only to "Our Trainings"
                    } ${item.subMenu ? "has-submenu" : ""}`}
                    onMouseEnter={() =>
                      item.subMenu && setActiveDropdown(index)
                    } // Show dropdown on hover
                    onMouseLeave={() => setActiveDropdown(null)} // Hide dropdown on mouse leave
                  >
                    <a href={item.link}>
                      {item.label}
                      {item.subMenu && (
                        <i className="fa fa-caret-down sub-dropdown"></i> // Dropdown icon
                      )}
                    </a>
                    {item.subMenu && (
                      <div
                        className={`dropup-content ${
                          activeDropdown === index ? "show" : ""
                        }`}
                      >
                        {item.subMenu.map((subItem, subIndex) => (
                          <a key={subIndex} href={subItem.link}>
                            {subItem.label}
                          </a>
                        ))}
                      </div>
                    )}
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </header>

      <div className="first-page-container1">
        <div className="first-page-heading1 font-size-index">
          Agile Training Experts
        </div>
      </div>
    </div>
  );
};

export default Header;
