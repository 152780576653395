import React from "react";
import { useState } from "react";
import "../assets/scss/viewProgrammes.scss";
import { RootState, AppDispatch } from "../store/store";
import { updateFormData, setErrors, resetFormData, submitBookCalForm  } from "../slices/bookCalSlice";
import BookACall from "../pages/BookACall";
const ViewProgrammes = () => {
 
  return (
    <div className="viewPrograms-main">
      {/* Banner Section */}
      <div className="top-page">
        <div className="first-page-container-vp">
          <div className=" banner-heading lokem-headers">
            <div>
              Empower your employees with the knowledge &amp; skills of the
              future
            </div>
            <div className="training-dates-button-section-vp">
              <a
                className="get-in-touch-vp lokem-button"
                href="#book-call-section"
              >
                Get In Touch
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Section 2 */}

      <div className="train-your-main-vp embed-main">
        <div className="row-vp tyt-text" id="row1">
          <fieldset className="foundation-fieldset">
            <legend className="foundation">Foundation</legend>

            {/* Column 1 */}
            <div className="column">
              <h2 className="tyt-heading-text-vp">INTRODUCTION TO AGILE</h2>
              <div className="each-para-div">
                Trains participants on the Agile values, principles &amp;
                practises. Includes various agile methodologies; such as Scrum
                &amp; Kanban. Participants also learn key concepts such as
                prioritisation techniques; agile ways of working etc
              </div>
              <h2 className="tyt-sub-heading-vp">WHO IS IT FOR?</h2>
              <div className="each-para-div">
                Anyone involved in projects; e.g: Project Managers, Programme
                Managers, Product teams, Quality Managers, Developers, Business
                Analysts, Testers, IT Managers etc
              </div>
            </div>

            {/* Column 2 */}
            <div className="column">
              <h2 className="tyt-heading-text-vp">INTRODUCTION TO SCRUM</h2>
              <div className="each-para-div">
                An introduction to the Scrum Framework. Participants learn an
                overview of the scrum framework; and the key roles,
                responsibilities &amp; meetings involved.
              </div>
              <h2 className="tyt-sub-heading-vp">WHO IS IT FOR?</h2>
              <div className="each-para-div">
                Anyone involved in Agile projects; e.g: Project Managers,
                Programme Managers, Product teams, Quality Managers, Developers,
                Business Analysts, Testers, IT Managers etc
              </div>
            </div>

            {/* Column 3 */}
            <div className="column">
              <h2 className="tyt-heading-text-vp">
                PSYCHOLOGICAL SAFETY AT WORK
              </h2>
              <div className="each-para-div">
                Psychological safety training is about creating an environment
                where people feel comfortable being authentic and honest- a key
                element for successful agile adoption within any organisation.
              </div>
              <div className="each-para-div">
                This course will provide participants with practical steps to
                foster a culture of psychological safety within teams and
                organisations.
              </div>
              <h2 className="tyt-sub-heading-vp">WHO IS IT FOR?</h2>
              <div className="each-para-div">Everyone working with teams</div>
            </div>
          </fieldset>
        </div>
      </div>

      <div className="train-your-main-vp embed-main">
        <div className="row-vp tyt-text" id="row1">
          <fieldset className="intermediate-fieldset">
            <legend className="intermediate">Intermediate</legend>
            <div className="column">
              <h2 className="tyt-heading-text-vp">SCRUM MASTER</h2>
              <div className="each-para-div">
                The Scrum Master is a key component of the scrum team. Through
                the Scrum Master's skill of clearing impediments, they
                facilitate the team to deliver products of high quality.
              </div>
              <div className="each-para-div">
                In this training participants gain a solid understanding of
                scrum and obtain a thorough understanding of the role of a Scrum
                Master. Learners deep dive into the underlying principles of
                scrum in addition to learning the art of facilitating the
                different scrum ceremonies.
              </div>
              <h2 className="tyt-sub-heading-vp">WHO IS IT FOR?</h2>
              <div className="each-para-div">
                Anyone involved in projects; e.g: Project Managers,Programme
                Managers, Product teams, Quality Managers, Developers, Business
                Analysts, Testers, IT Directors &amp; Managers etc.
              </div>
            </div>
            <div className="column">
              <h2 className="tyt-heading-text-vp">PRODUCT OWNER</h2>
              <div className="each-para-div">
                The Product Owner plays a vital role of bridging business
                strategy and product execution.
              </div>
              <div className="each-para-div">
                Participants will learn product ownership within Scrum and how
                to maximize the value of products and return on investments.
                Product Owners enable Scrum Teams to deliver a high-quality
                product. Learners will learn how to set the team's priorities
                and the scope for the product.
              </div>
              <h2 className="tyt-sub-heading-vp">WHO IS IT FOR?</h2>
              <div className="each-para-div">
                Anyone involved in Agile projects; e.g: Project Managers,
                Programme Managers, Product teams, Quality Managers, Developers,
                Business Analysts, Testers, IT Directors &amp; Managers etc.
              </div>
            </div>
            <div className="column">
              <h2 className="tyt-heading-text-vp">AGILE FOR REMOTE TEAMS</h2>
              <div className="each-para-div">
                With businesses increasingly moving to a remote model for their
                ways of working, it is important to understand practical ways of
                working in this manner in order to maintain productivity,
                communication &amp; motivation.
              </div>
              <h2 className="tyt-sub-heading-vp">WHO IS IT FOR?</h2>
              <div className="each-para-div">
                Anyone involved in Agile projects; e.g: Project Managers,
                Programme Managers, Product teams, Quality Managers, Developers,
                Business Analysts, Testers, IT Directors &amp; Managers etc.
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <div className="train-your-main-vp embed-main">
        <div className="row-vp tyt-text" id="row1">
          <fieldset className="advanced-fieldset">
            <legend className="advanced">Advanced</legend>

            {/* Agile Tools for Beginners */}
            <div className="column">
              <h2 className="tyt-heading-text-vp">AGILE TOOLS FOR BEGINNERS</h2>
              <div className="each-para-div">
                Tools training for various Agile Tools for teams. Including
                standard work management tools such as: Jira, Trello, Planner.
                As well as collaborative tools such as: Miro, Mural, etc.
              </div>
              <h2 className="tyt-sub-heading-vp">WHO IS IT FOR?</h2>
              <div className="each-para-div">
                Anyone involved in Agile projects; e.g: Project Managers,
                Programme Managers, Product teams, Quality Managers, Developers,
                Business Analysts, Testers, IT Directors &amp; Managers, Scrum
                Master.
              </div>
            </div>

            {/* SAFe for Teams */}
            <div className="column">
              <h2 className="tyt-heading-text-vp">SAFe for Teams</h2>
              <div className="each-para-div">
                SAFe for Teams will teach you all about your role on an Agile
                team and how your team works with others as part of an ART.
              </div>
              <h2 className="tyt-sub-heading-vp">WHO IS IT FOR?</h2>
              <div className="each-para-div">
                Anyone involved in Agile projects; e.g: Project Managers,
                Programme Managers, Product teams, Quality Managers, Developers,
                Business Analysts, Testers, IT Directors &amp; Managers, Scrum
                Master.
              </div>
            </div>

            {/* SAFe Scrum Master */}
            <div className="column">
              <h2 className="tyt-heading-text-vp">SAFe Scrum Master</h2>
              <div className="each-para-div">
                The SAFe® Scrum Master course equips attendees to coach Agile
                teams to deliver business value at scale. The course teaches you
                the tactical skills needed to be an effective Scrum Master in a
                SAFe organization.
              </div>
              <h2 className="tyt-sub-heading-vp">WHO IS IT FOR?</h2>
              <div className="each-para-div">
                Anyone involved in Agile projects; e.g: Project Managers,
                Programme Managers, Product teams, Quality Managers, Developers,
                Business Analysts, Testers, IT Directors &amp; Managers, Scrum
                Master.
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <div className="train-your-main-vp" style={{ background: "#fff" }} id="book-call-section">
      <div className="contact-text">
  Can't find the right course?
  <br />
  <div className="dont-worry">
    Don't worry, we'll create the perfect course for you. Enquire below
  </div>
  </div>
  <BookACall />
</div>
    </div>
    
   

  );
};

export default ViewProgrammes;
