import React from "react";
import "../../assets/scss/blogs/agileCulture.scss";
import PageBanner from "../PageBanner";
import Comments from "./Comments";

const AgileCulture = () => {
  return (
    <div className="agileCulture-main">
      <PageBanner section="agileCulture" />
      <div className="train-your-main-agile">
        <div className="tyt-paras-agile">
          <div className="secs-tyt-cols-agile">
            <div className="tyt-text-agile">
              <div className="each-para-div-agile">
                <div className="new-para-agile">
                  <div className="para-one-agile">
                    With the pandemic in the rear mirror, companies are
                    realising the importance of adapting and modifying their
                    business structures in order to be ready to face constant
                    and unpredictable challenges. This is one of many reasons
                    why more organisations are adopting Agile practices.
                    However, many companies focus on their processes being more
                    Agile but often forget the importance of the people being
                    Agile. Companies who work smarter do both, because an
                    organisation needs Agile workers to be successful.
                  </div>
                  <div className="new-para-agile">
                    According to a report by Wiley titled “
                    <i>
                      <a
                        target="_blank"
                        href="https://www.discprofile.com/CMS/media/doc/ed/agility-unlocked.pdf"
                      >
                        Cognitive vs Emotional intelligence
                      </a>
                    </i>
                    “, the key to developing an Agile workforce is to be
                    emotionally intelligent. The report argues that the Agile
                    culture demands people who are resilient and proactive,
                    while the Agile mindset requires individuals who are
                    empathetic and keen listeners.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-agile">
        <div className="tyt-paras-agile flex-dir-agile">
          <div className="secs-tyt-cols-agile">
            <div className="tyt-text-agile">
              <div className="tyt-sub-heading-agile">
                The dangers of ignoring emotional intelligence
              </div>
              <div className="each-para-div-agile">
                <div className="new-para-agile remove-bottom-agile">
                  <div>
                    Wiley surveyed 2,500 professionals in 2020 and discovered
                    some interesting points about emotional intelligence in the
                    workplace.
                  </div>
                </div>
                <ul className="bullets-agile">
                  <li>
                    80% of those surveyed had worked on teams where low
                    emotional intelligence hurt the general productivity
                  </li>
                  <li>
                    More than 40% had actually quit a job after working with
                    people with low emotional intelligence
                  </li>
                  <li>
                    Managers spent more time resolving interpersonal issues than
                    the work their employees do
                  </li>
                </ul>
                <div>
                  These findings show that if an organisation does not make a
                  serious commitment to integrating emotional intelligence into
                  their leadership and grant opportunities for training in this
                  field, there will be serious repercussions.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-agile">
        <div className="tyt-paras-agile">
          <div className="secs-tyt-cols-agile">
            <div className="tyt-text-agile">
              <div className="tyt-sub-heading-agile">
                Cognitive vs emotional intelligence
              </div>
              <div className="each-para-div-agile">
                <div className="new-para-agile">
                  <div>
                    When trying to be Agile, one can often confuse cognitive
                    with emotional intelligence. We tend to encourage concepts
                    such as self-development, willingness to learn and the
                    expansion of knowledge, and the emphasis on these often
                    means that emotional intelligence is overlooked.
                  </div>
                  <div className="new-para-agile">
                    <div>
                      Interactions between different people in the team can
                      become strained and at times challenging because everyone
                      is unique with their own preferences and quirks. An
                      emotionally intelligent Scrum Master will be able to
                      observe and mediate the situation, ensuring that all
                      parties involved are heard without escalating the
                      conflict. These scenarios cement the Scrum Master as the
                      basis of a successful, resilient and adaptable team.
                    </div>
                  </div>
                  <div className="new-para-agile">
                    <div>
                      Now to answer the question:{" "}
                      <i>
                        Does the Agile culture need more emotional intelligence?
                      </i>{" "}
                      Yes it does! Wiley’s research confirms that more Agile
                      workers are weighing the importance of emotional
                      intelligence in their leaders and their peers and a
                      significant number of managers now acknowledge the concept
                      is needed for a cohesive team.
                    </div>
                  </div>
                  <div className="new-para-agile">
                    <div>
                      Agile teams that prioritise emotional intelligence can
                      improve decision-making, strengthen connections and better
                      understand and manage each other’s emotions, leading to a
                      healthier and happier work environment.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Comments />
    </div>
  );
};

export default AgileCulture;
