import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

interface BookCalFormState {
  formData: {
    name: string;
    companyName: string;
    email: string;
    contactNumber: string;
    jobRole: string;
    companySize: string;
  };
  errors: {
    name: string;
    companyName: string;
    email: string;
    contactNumber: string;
    jobRole: string;
    companySize: string;
  };
  responseMessage: string;
  status: "idle" | "loading" | "succeeded" | "failed";
}

const initialState: BookCalFormState = {
  formData: {
    name: "",
    companyName: "",
    email: "",
    contactNumber: "",
    jobRole: "",
    companySize: "",
  },
  errors: {
    name: "",
    companyName: "",
    email: "",
    contactNumber: "",
    jobRole: "",
    companySize: "",
  },
  responseMessage: "",
  status: "idle",
};

export const submitBookCalForm = createAsyncThunk(
  "bookCalForm/submit",
  async (formData: BookCalFormState["formData"], { rejectWithValue }) => {
    try {
     
      const response = await fetch(
        "http://localhost:8018/api/v1/contact",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();
      if (!response.ok) {
        return rejectWithValue(data.error);
      }
      return data.message;
    } catch (error) {
      return rejectWithValue("An error occurred while submitting the form.");
    }
  }
);

const bookCalSlice = createSlice({
  name: "bookCalForm",
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setErrors: (state, action) => {
      state.errors = { ...state.errors, ...action.payload };
    },
    resetFormData: (state) => {
      state.formData = initialState.formData; // Reset formData to initial state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitBookCalForm.pending, (state) => {
        state.status = "loading";
      })
      .addCase(submitBookCalForm.fulfilled, (state, action) => {
        state.responseMessage = action.payload;
        state.formData = initialState.formData; // Reset form data
        state.status = "succeeded";
      })
      .addCase(submitBookCalForm.rejected, (state, action) => {
        state.responseMessage = action.payload as string;
        state.status = "failed";
      });
  },
});

export const { updateFormData, setErrors, resetFormData } = bookCalSlice.actions;
export default bookCalSlice.reducer;
