import React from "react";
import "../../assets/scss/blogs/simiJourney.scss";

import img1 from "../../assets/images/blogs/blogs1.png";

const SimiJourney = () => {
  return (
    <div className="SimiJourney-main">
      {" "}
      <div className="train-your-main-semi bottom-space-semi">
        <div className="simi-text">
          Simi's journey: Project Assistant to Agile Delivery Manager
        </div>
      </div>
      <div className="train-your-main-semi bottom-space-semi">
        <div className="quote-text-semi">
          <span className="quotes">“</span>Agile training at Lokem will be the
          best decision you will ever make. If you are thinking about changing
          your career and getting into tech then this is the place to do it.
          <span className="quotes">”</span>
        </div>
      </div>
      <div className="train-your-main-semi">
        <div className="tyt-paras-semi">
          <div className="secs-tyt-cols-semi">
            <div className="tyt-text-semi">
              <div className="each-para-div-semi">
                <div className="bottom-space-semi">
                  Simi, a 28 year from East London, successfully transitioned
                  from a career in the Research Sector into Tech!
                </div>
                <div>
                  When Simi started his journey at Lokem, he jumped right in and
                  learned all about Agile, managing teams and projects. Now a
                  successful Agile Delivery Manager at DNATA, Simi tells us
                  about his training journey with Lokem.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="train-your-main-semi">
        <div className="img-simi-main">
          <img src={img1} className="img-simi" />
        </div>
      </div>
      <div className="train-your-main-semi bottom-space-semi">
        <div className="tyt-paras-semi flex-dir-semi">
          <div className="secs-tyt-cols-semi">
            <div className="tyt-text-semi">
              <div className="tyt-sub-heading-semi">What is your title?</div>
              <div className="each-para-div-semi">
                <div className="bottom-space-semi">Agile Delivery Manager</div>
              </div>
            </div>
          </div>
        </div>

        <div className="tyt-paras-semi">
          <div className="secs-tyt-cols-semi">
            <div className="tyt-text-semi">
              <div className="tyt-sub-heading-semi">
                What were you doing before you began your journey at Lokem and
                what was your motivation to consider the training?
              </div>
              <div className="each-para-div-semi">
                <div className="bottom-space-semi">
                  I was working in the Clinical Research sector. I was a Project
                  assistant/Project Administrator at the time and I wanted to
                  progress but progression within that industry was really slow
                  and I also felt there was bias in that industry in regards to
                  age. The older you are, the more inexperienced you might look,
                  which I didn't like, so I needed an alternative that gave me
                  higher prospects and higher pay.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tyt-paras-semi">
          <div className="tyt-text-semi">
            <div className="tyt-sub-heading-semi">
              What was your experience like at Lokem?
            </div>
            <div className="each-para-div-semi">
              <div className="bottom-space-semi">
                Lokem was fantastic. I was lucky enough to even do the in-person
                workshop in January 2020 just before lockdown, so I got to meet
                my cohort as well as Kemi and Lola.
              </div>
              <div className="bottom-space-semi">
                The workshop convinced me 100% that this was the path that I
                wanted to take in my life and it was also the best financial
                decision I have ever made in my 28 years of life.
              </div>
              <div className="bottom-space-semi">
                It was a crazy journey doing our Scrum ceremonies as well as
                attending training sessions in the evenings/weekends whilst
                still juggling my day job. It was hard but I was determined.
              </div>
              <div className="bottom-space-semi">
                I think lockdown made it so much easier to progress as I was
                working from home. I spoke regularly with my cohorts to keep us
                motivated and accountable . The trainings were very interactive
                and I learned so much and everything you learn with Lokem is
                transferable into the actual real life Agile environment
              </div>
            </div>
          </div>
        </div>

        <div className="tyt-paras-semi">
          <div className="tyt-text-semi">
            <div className="tyt-sub-heading-semi">
              How did you find the hands - on work experience element of the
              training programme?
            </div>
            <div className="each-para-div-semi">
              <div className="bottom-space-semi">
                The work experience part was for me the most crucial part of the
                process . Without it I don't think I would have been confident
                or even comfortable enough to take on my roles that I did so far
                in my Agile career.
              </div>
              <div className="bottom-space-semi">
                Everything I did in the work experience element transferred
                itself to the real world. For me it was the most crucial part of
                this process because it gives you the actual genuine experience
                to transform Agile teams into cross functional as well as a self
                organising team that knows how to solve its problems.
              </div>
            </div>
          </div>
        </div>

        <div className="tyt-paras-semi">
          <div className="tyt-text-semi">
            <div className="tyt-sub-heading-semi">
              What was the support like on the platform and throughout your
              training?
            </div>
            <div className="each-para-div-semi">
              <div className="bottom-space-semi">
                Support was 10/10. Any help I needed I knew the experts I could
                reach out to.
              </div>
              <div className="bottom-space-semi">
                I had a mentor when it came to interview prep and CV writing
                etc, so I was grateful for the support I got. All the things I
                learned from my mentor at Lokem are still in ingrained in my
                head and I make sure i pass on that knowledge to people that
                need it.
              </div>
            </div>
          </div>
        </div>

        <div className="tyt-paras-semi">
          <div className="secs-tyt-cols-semi">
            <div className="tyt-text-semi">
              <div className="tyt-sub-heading-semi">
                What has happened since completing the training?
              </div>
              <div className="each-para-div-semi">
                <div>
                  <div className="bottom-space-semi">
                    Since my training I secured my first Scrum Master role at a
                    company called ACUE that had a development partnership/
                    contract with BT so I worked with BT Agile teams in building
                    key software deliverables over several release trains at BT.
                  </div>
                  <div className="bottom-space-semi">
                    A great opportunity came up to work as a Certified SAFe
                    Release Train Engineer for a SaaS software company called
                    FOURTH, where I was coaching and identifying key risks and
                    gaps in Agility of 10-12 Agile teams as well as
                    collaborating with Agile and Tech leaders to facilitate the
                    adherence to the Scaled Agile Framework. Now I’m at my
                    current company called DNATA Travel Group where I am an
                    Agile Delivery Manager overseeing Agile Delivery of key
                    ticketing and flights software for key players in the
                    Airline industry like British Airways, KLM, and Emirates
                    Airline Group.
                  </div>
                  <div className="bottom-space-semi">
                    Qualifications wise I have obtained the PSM1 and Scaled
                    Agile framework (SAFe) certification. I am currently
                    studying to acquire the Azure Fundamentals certification
                    which will help further my knowledge of Azure cloud based
                    solutions to help me further in my day to day work. My new
                    role involves some travel so I will be going to Dubai in the
                    summer!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tyt-paras-semi">
          <div className="secs-tyt-cols-semi">
            <div className="tyt-text-semi">
              <div className="tyt-sub-heading-semi">
                What difference has taking the training made to your life?
              </div>
              <div className="each-para-div-semi">
                <div>
                  <div className="bottom-space-semi">
                    Quality of life has definitely improved for sure! It has
                    taught me that you have to constantly challenge yourself if
                    you want to get to the next level.
                  </div>
                  <div className="bottom-space-semi">
                    The training has opened me out to a career that I didn't
                    even know was possible for me 3 years ago. Best decision I
                    have ever made.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tyt-paras-semi">
          <div className="secs-tyt-cols-semi">
            <div className="tyt-text-semi">
              <div className="tyt-sub-heading-semi">
                What advice would you give to anyone thinking about changing
                careers &amp; applying for Agile training with Lokem?
              </div>
              <div className="each-para-div-semi">
                <div className="bottom-space-semi">
                  Agile training at Lokem will be the best decision you will
                  ever make. If you are thinking about changing your career and
                  getting into tech then this is the place to do it.
                </div>
                <div className="bottom-space-semi">
                  {" "}
                  Do not even think for one second that you need to have IT
                  experience to do well here because you really don't.
                </div>
                <div className="bottom-space-semi">
                  Have a positive attitude and be relentless in your pursuit
                  when you join Lokem and success is almost guaranteed.
                </div>
                <div className="bottom-space-semi">
                  For those that think they are too old or too young to do this
                  course - do not limit yourself! I have worked with Scrum
                  Masters who are much younger than me and I am 28. The oldest
                  Scrum Masters I have seen are well into their 50s - so age is
                  nothing but a number. If you are driven you will succeed no
                  matter what.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tyt-paras-semi">
          <div className="secs-tyt-cols-semi">
            <div className="tyt-text-semi">
              <div className="tyt-sub-heading-semi">
                What is your biggest achievement that you are proud of?
              </div>
              <div className="each-para-div-semi">
                <div>
                  I am proud of actually becoming a Scrum Master in my 1st role
                  considering the fact that I wasn't even that confident when I
                  first started at Lokem.
                </div>
                <div className="bottom-space-semi">
                  {" "}
                  I had a bit of imposter syndrome initially but I overcame it
                  when the time for interviews came round and I gave my all and
                  succeeded.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tyt-paras-semi">
          <div className="secs-tyt-cols-semi">
            <div className="tyt-text-semi">
              <div className="tyt-sub-heading-semi">
                What are you doing to ensure you continue to grow and develop in
                the world of tech?
              </div>
              <div className="each-para-div-semi">
                <div>
                  <div className="bottom-space-semi">
                    I have obtained the PSM1 and SAFe certifications and am
                    currently studying for the Azure Fundamentals certification.
                    I follow several Agile YouTubers like “your agile coach” and
                    “ scrummastered” who provide regular tips and insights on
                    how to become a better Agile professional.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tyt-paras-semi">
          <div className="secs-tyt-cols-semi">
            <div className="tyt-text-semi">
              <div className="tyt-sub-heading-semi">
                Do you want to know more about Simi? Following him on LinkedIn
              </div>
              <div className="each-para-div-semi">
                <div>
                  <a
                    href="https://www.linkedin.com/in/simi-oremosu-6894b0151"
                    target="_blank"
                  >
                    https://www.linkedin.com/in/simi-oremosu-6894b0151
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimiJourney;
