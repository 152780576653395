import React from "react";
import { Link } from "react-router-dom";
import "../assets/scss/projectManagerTraining.scss";
import img1 from "../assets/images/po-training1.jpg";
import img4 from "../assets/images/po-expertise.png";
import img5 from "../assets/images/po-mindset.png";
import img6 from "../assets/images/po-agility.png";
import img7 from "../assets/images/po-delivery.png";
//import img8 from "../assets/images/po-training2.jpg";
import { useNavigate } from "react-router-dom";
import AdvCoupon from "../pages/AdvCoupon";


const ProjectManagerTraining = () => {

  const navigate = useNavigate();
  return (
    <>
    <AdvCoupon/>
    <div className="ProjectManagerTraining-main">
      {/* Banner */}
      <div className="top-page">
  <div className="first-page-container">
    <div className="first-page-heading banner-heading lokem-headers">
      Become a Certified Project Manager
    </div>
  </div>
  <div className="training-dates-button-section book-btn-position">
    <button
      className="book-place-button lokem-button" onClick={() => navigate("/project-manager")}
      data-course="3"
    >
      BOOK NOW
    </button>
  </div>
</div>

{/* Section 2 */}
<div className="train-your-main training-overview-main">
  <div className="max-width-div">
    <div className="overview-cols-main">
      {/* Column 1 */}
      <div className="overview-col1">
        <div className="tyt-heading-main">
          <div className="tyt-heading tyt-heading-text">Training Overview</div>
        </div>
        <div className="tyt-text">
          <div className="each-para-div">
            <div>
              The purpose of this Project Manager Training is to give participants the information and abilities needed to oversee large-scale projects effectively, from the planning and development stage all the way through to the deployment and completion stage.
            </div>
          </div>
          <div className="each-para-div">
            <div>
              Project execution, control, closure, planning, risk management, stakeholder management, and team dynamics are just a few of the subjects covered in this course. Along with introducing various project management methodologies, the course aids in the development of student's personal project management abilities and expertise.
            </div>
          </div>
        </div>
      </div>

      {/* Column 2 */}
      <div className="overview-col2">
        <div>
          <img
            className="course-overview-image1"
            src={img1}
            alt="Scrum Training Overview 1"
          />
        </div>
      </div>
    </div>
  </div>
</div>



      {/* Section 3*/}
      <div className="tobj-main">
  <div className="tyt-heading tyt-heading-text tobj-heading">Training Objectives</div>
  <div className="tobj-sub">Students will learn how to:</div>
  <div className="tobj-col-main tyt-text">
    <div className="tobj-row">
      <div className="tobj-col1">
        <div className="tyt-sub-heading tobj-color">
          <img src={img4} className="tobj-img" alt="Expertise" />
        </div>
        <div className="each-para-div">
          <div>
            Identify the core concepts, processes and practices of project management. Utilize the PMP methodology and its tools to plan and manage projects
          </div>
        </div>
      </div>
      <div className="tobj-col1">
        <div className="tyt-sub-heading tobj-color">
          <img src={img5} className="tobj-img" alt="Mindset" />
        </div>
        <div className="each-para-div">
          <div>
            Understand the roles and responsibilities of stakeholders in project management.
          </div>
        </div>
      </div>
    </div>
    <div className="tobj-row">
      <div className="tobj-col1">
        <div className="tyt-sub-heading tobj-color">
          <img src={img6} className="tobj-img" alt="Agility" />
        </div>
        <div className="each-para-div">
          <div>
            Apply earned value concepts to successful projects. Examine project risk management and how to identify and manage project risks.
          </div>
        </div>
      </div>
      <div className="tobj-col1">
        <div className="tyt-sub-heading tobj-color">
          <img src={img7} className="tobj-img" alt="Delivery" />
        </div>
        <div className="each-para-div">
          <div>
            Improve quality control practices by developing an understanding of quality management and improvement. Learn how to report progress, develop project closure reports and activate key project lessons learned.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    {/* Section 4 */}
    <div className="train-your-main training-overview-main">
  <div className="max-width-div">
    <div className="overview-cols-main">
      {/* Overview Column 1 */}
      <div className="overview-col1">
        <div className="tyt-heading-main">
          <div className="tyt-heading tyt-heading-text">What Will You Learn?</div>
        </div>
        <div className="tyt-text">
          <div className="each-para-div padding-bottom-para">
            <div>
              By working on live projects and gaining hands-on experience, you will constantly be learning and developing the essential skills of a Product Owner in order to become PSPO certified. You will gain good knowledge of product ownership, including:
            </div>
          </div>
          <div className="each-para-div">
            <div>
              <div className="modules-covered">
                <div className="mc-heading tyt-sub-heading">Modules Covered</div>
                <div> Project Management </div>
                <div> Project Integration Management </div>
                <div> Project Scope Management </div>
                <div> Project Schedule Management </div>
                <div> Project Cost Management </div>
                <div> Project Quality Management </div>
                <div> Project Resource Management </div>
                <div> Project Communication Management </div>
                <div> Project Risk Management </div>
                <div> Project Procurement Management </div>
                <div> Project Stakeholder Management </div>
              </div>
              <div>
                <div className="fac-course tyt-sub-heading">Who Facilitates The Course?</div>
                <div>
                  Our training workshops are delivered by expert Agile consultants working at globally renowned
                  companies including Barclaycard, Lloyds, Selfridges &amp; Vodafone.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Overview Column 2 */}
      <div className="overview-col2">
        <div>
          <div className="training-dates-main tyt-text">
            <div className="training-dates-text tyt-sub-heading">Training Dates</div>
            <div className="location-online">Location: online</div>
            <div>Followed by a minimum of 3 months online training</div>
            <div className="package-includes">
              (Package also includes access to work experience opportunities)
            </div>
            <div className="training-dates-button-section booknow-style">
              <link media="all" rel="stylesheet" href="css/limitedOffer.css" />
              <div className="lf-use-code-main">
                Use code <span className="lf-coupon-code">AGILE50</span> for 50% off
              </div>
              <button className="book-place-button lokem-button" data-course="2" onClick={() => (window.location.href = "/TraineeDetails")}>
                BOOK NOW
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


   </div>
</>
  );
};

export default ProjectManagerTraining;
