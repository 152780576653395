import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../assets/scss/corporatetraining.scss";
import img1 from "../assets/images/training1.jpeg";
import img2 from "../assets/images/training2.jpeg";
import img3 from "../assets/images/myTraining3.jpeg";
import img4 from "../assets/images/training4.jpeg";
import whyLokem from "../assets/images/whyLokem.jpg";
import sectionsData from "../assets/json/sectionsData.json";
import BookACall from "../pages/BookACall";
interface Section {
  image: string;
  title: string;
  content: string | string[]; // content can be either a string or an array of strings
}

const CorporateTraining = () => {
  const [sections, setSections] = useState<Section[]>([]);

  useEffect(() => {
    // Map the imported JSON data to include the correct image imports
    const mappedSections = sectionsData.map((section: any) => ({
      ...section,
      image: require(`../assets/images/${section.image}`), // Dynamically load the image using require
    }));
    setSections(mappedSections);
  }, []);

 
  return (
    <div className="corporate-main">
      {/* Banner Section */}
      <div className="top-page">
        <div className="first-page-container">
          <div className="first-page-heading banner-heading lokem-headers">
            Upskill your Employees in Agile &amp; Transform Your Organisation.
          </div>
          <div className="first-section-sub">
            Upskill existing teams with Agile training programmes. Thrive in an
            increasing dynamic economy.
          </div>
          <div className="training-dates-button-section">
            <div className="mar-right">
              <a
                className="get-in-touch-corporate lokem-button"
                href="#book-call-section"
              >
                Get In Touch
              </a>
            </div>
            <div>
              <a
                className="view-program-top view-program lokem-button"
                href="ViewProgrammes"
              >
                View programmes
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Section 2 */}

      <div className="train-your-main-corporate images-sec-main">
        <div className="tyt-heading-main">
          <div className="tyt-heading-corporate">
            We help your business attain &amp; sustain success in today’s
            fast-paced business world
          </div>
        </div>

        {sections.map((section, index) => (
          <div
            key={index}
            className={`tyt-paras ${index % 2 === 1 ? "flex-dir" : ""}`}
          >
            <div className="secs-tyt-col1">
              <div className="tyt-text">
                <div className="each-para-div-corporate">
                  <div className="offer-para1">
                    <img
                      className="sec1-image"
                      src={section.image}
                      alt={section.title}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="secs-tyt-col2">
              <div className="tyt-text">
                <div className="each-para-div-corporate tyt-sub-heading-corporate">
                  {section.title}
                </div>
                {Array.isArray(section.content) ? (
                  section.content.map((text, idx) => (
                    <div key={idx} className="each-para-div-corporate">
                      <div className="offer-para1 secs-font">{text}</div>
                    </div>
                  ))
                ) : (
                  <div className="each-para-div-corporate">
                    <div className="offer-para1 secs-font">
                      {section.content}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Section 3 */}
      <div className="train-your-main-corporate embed-main-corporate">
        <div className="tyt-heading-corporate padding-bot">
          Embed the Skills Your Business Requires For Sustained Success
        </div>
        <div className="row-corporate tyt-text">
          <div className="column-corporate first-col">
            <div className="embed-heading each-para-div-corporate">
              CUSTOMER FOCUSED
            </div>
            <div className="each-para-div-corporate ">
              Delight your customers with customer - centric solutions.
            </div>
          </div>
          <div className="column-corporate">
            <div className="embed-heading each-para-div-corporate">
              AGILE LEADERS
            </div>
            <div className="each-para-div-corporate ">
              Develop the future skills required by leaders at all levels to
              effectively lead and manage teams.
            </div>
          </div>
          <div className="column-corporate last-col">
            <div className="embed-heading each-para-div-corporate">
              OPTIMISED BUSINESS OPERATIONS
            </div>
            <div className="each-para-div-corporate ">
              Increase time to market. Decrease waste. Optimise the flow of
              value to your customers.
            </div>
          </div>
        </div>
        <div className="view-program-main">
          <Link
            to="/viewProgrammes"
            className="view-program-bottom view-program lokem-button"
          >
            View programmes
          </Link>
        </div>
      </div>

      {/* Section 4 */}
      <div
        className="train-your-main-corporate"
        style={{ background: "gray", color: "#fff" }}
      >
        <div className="max-width-div">
          <div className="tyt-text-heading tyt-heading-text">Why Lokem?</div>
          <div className="tyt-heading-line-main">
            <hr className="tyt-heading-line" />
          </div>
          <div className="why-lokem-main-text">
            At Lokem, we take great pride in our mission to nurture
            transformational changes within your organisation by equipping your
            employees with the necessary skills and tools they need to thrive
            and survive in an ever-changing, highly competitive market.
          </div>
          <div className="discovery-row1">
            <div className="discovery-row1-col1">
              <div className="tyt-text">
                <div className="each-para-div-corporate">
                  Our passion fuels our proactive approach to product
                  development, and it keeps Agility at the core of who we are.
                  It keeps us on the path to support you to achieve success with
                  every step that we take together.
                </div>
                <div className="each-para-div-corporate">
                  The driving force behind our many successes across many
                  sectors over the years is our excellent team of Agile coaches,
                  Training consultants and Personnel. Our workforce is made up
                  of individuals with a wide range of expertise and skill sets,
                  acquired from working with different clients around the world.
                </div>
                <div className="each-para-div-corporate">
                  Our devotion to helping our clients grow and sustain their
                  businesses is the reason why our training program is unique
                  for every client and their employees.
                </div>
              </div>
            </div>
            <div className="discovery-row1-col2">
              <div>
                <img
                  className="discovery-image"
                  src={whyLokem}
                  alt="Why Lokem"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section 5 */}
      <div
        className="train-your-main-corporate"
        style={{ background: "#fff" }}
        id="book-call-section"
      >
       <BookACall/>
      </div>
    </div>
  );
};

export default CorporateTraining;
