import React, { useState } from "react";
import "../assets/scss/HomeRequest.scss";

interface CallbackFormProps {
  buttonText: string;
  showInterestField?: boolean;
}

const CallbackForm: React.FC<CallbackFormProps> = ({
  buttonText,
  showInterestField = true, // Default to true
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    interest: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    number: "",
    interest: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = () => {
    const newErrors = {
      name: formData.name ? "" : "Name is required",
      email:
        formData.email && /\S+@\S+\.\S+/.test(formData.email)
          ? ""
          : "Valid email is required",
      number:
        formData.number && /^\d{10,16}$/.test(formData.number)
          ? ""
          : "Valid 10-digit phone number is required",
      interest:
        showInterestField && !formData.interest
          ? "Please select your interest"
          : "",
    };

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === "");
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      setSubmitted(true);
    }
  };

  return (
    <form className="callbackFormMain">
      <div className="contactMain">
        <div className="contactMainForm">
          <div className="contactFieldDiv">
            <input
              type="text"
              name="name"
              className="contactInput"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <div className="error-text">{errors.name}</div>}
          </div>
          <div className="contactFieldDiv">
            <input
              type="text"
              name="email"
              className="contactInput"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <div className="error-text">{errors.email}</div>}
          </div>
          <div className="contactFieldDiv">
            <input
              type="text"
              name="number"
              className="contactInput"
              placeholder="Contact Number"
              value={formData.number}
              onChange={handleChange}
            />
            {errors.number && <div className="error-text">{errors.number}</div>}
          </div>
          {showInterestField !== false && (
            <div className="contactFieldDiv">
              <select
                name="interest"
                className="contactInput interest-select"
                value={formData.interest}
                onChange={handleChange}
              >
                <option value="">Please select your interest</option>
                <option value="Scrum Master">SM Training</option>
                <option value="Business Analyst">BA Training</option>
                <option value="Product Owner">PO Training</option>
              </select>
              {errors.interest && (
                <div className="error-text">{errors.interest}</div>
              )}
            </div>
          )}
          <div className="contactFieldDiv">
            <button className="contactSubmitBtn" onClick={handleSubmit}>
              <div className="btnSpinner"></div>
              <span className="buttonText">{buttonText}</span>
            </button>
          </div>
        </div>
        {submitted && (
          <div className="contactMsgMain">
            Thank you for contacting us. We will be in touch with you shortly.
          </div>
        )}
      </div>
    </form>
  );
};

export default CallbackForm;
