import React from 'react';
import Marquee from 'react-fast-marquee';
import "../assets/scss/advCoupon.scss";


const LimitedOffer = () => {
  return (
    <div className="adv-main">
      <div className="main">
        <div className="adv-col1">
        <Marquee speed={100} gradient={false}>
            <div className="use-code-main">
              <span className="limited-offer">Limited offer</span> - Use code <span className="coupon-code">AGILE50</span> for 50% off
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default LimitedOffer;
