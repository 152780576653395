import React from "react";
import "../../assets/scss/Admin/addCoupon.scss";
import { useState } from "react";

const AddCoupon = () => {
  const [formData, setFormData] = useState({
    course: "",
    coupon: "",
    discount: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Form Data Submitted: ", formData);
  };

  return (
    <div className="add-coupon-container">
      <h1>Add coupon</h1>
      <div className="add-coupon-wrapper">
        <form onSubmit={handleSubmit} className="add-coupon-form">
          <div className="add-coupon-form-group">
            <label htmlFor="course">Select course</label>
            <select
              id="course"
              name="course"
              value={formData.course}
              onChange={handleChange}
              required
            >
              <option value="">Please select your interest</option>
              <option value="SM Training">SM Training</option>
              <option value="BA Training">BA Training</option>
              <option value="PO Training">PO Training</option>
              <option value="PM Training">PM Training</option>
            </select>
          </div>
          <div className="add-coupon-form-group">
            <label htmlFor="coupon">Coupon code</label>
            <input
              type="text"
              id="coupon"
              name="coupon"
              placeholder=""
              value={formData.coupon}
              onChange={handleChange}
              required
            />
          </div>
          <div className="add-coupon-form-group">
            <label htmlFor="discount">Discount amount</label>
            <input
              type="text"
              id="discount"
              name="discount"
              placeholder=""
              value={formData.discount}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="submit-btn">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddCoupon;
