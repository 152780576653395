// store.js
// store.js
import dataReducer from "../reducers/reducer";
import helpFaqReducer from "../reducers/reducer";
import { configureStore } from "@reduxjs/toolkit";
import { createLogicMiddleware } from "redux-logic";
import logicArray from "../logic/Help_faq";
import contactReducer from '../slices/contactSlice';
import bookCallReducer from '../slices/bookCalSlice';


const logicMiddleware = createLogicMiddleware(logicArray);

export type RootState = ReturnType<typeof store.getState>; // RootState type for useSelector
export type AppDispatch = typeof store.dispatch; // AppDispatch type for useDispatch

const store = configureStore({
  reducer: {
    data: dataReducer,
    helpFaqReducer: helpFaqReducer,
    contactForm: contactReducer,
    bookCall: bookCallReducer,
  },
  // Add the logicMiddleware directly in the middleware array
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(logicMiddleware),
});

export default store;